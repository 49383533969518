import React, { useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { getCompareData, setCompareData } from "../../../actions/compareActions";

const ServiceItem = ({ itemData, compareData, ...props }) => {
    const obj = {
        slug: `${itemData.storeName}-${props.title}`.replace(/\s/, "").toLowerCase(),
        info: props.desc,
        title: props.title,
        price: props.price,
        market: itemData?.targetMarket,
        store: itemData?.storeName,
        storeEmail: itemData.email,
        section: "service",
    };

    const handleEnvelope = useCallback((title) => {
        window.location.href = `mailto:${itemData.email}?subject=Inquiry on ${title} services`;
    });

    const addToCompare = useCallback((item, action = "add") => {
        if (action === "add") {
            if (compareData.error) alert(compareData.error);
            if (compareData?.items && Object.keys(compareData?.items).length && compareData?.market !== item?.market) {
                const goNext = window.confirm("You can only compare SERVICES from same MARKET TYPE.\nWould you like to change the MARKET TYPE?");
                if (!goNext) return;
            }
            localStorage.setItem("scrollTop", true);
        }
        props.setCompareData(action, item);
    });

    useEffect(() => {
        props.getCompareData();
    }, []);

    return (
        <div className="col-md-4 col-sm-6">
            <div className="text-block">
                <div className="text-caption">
                    <h3 className="title">{props.title}</h3>
                    <span className="h6 text-muted">{props.price}</span>
                    <p className="my-2">{props.desc}</p>
                </div>
                <div className="text-center my-3">
                    <button type="button" onClick={() => handleEnvelope(props.title)} className="btn btn-sm btn-warning mr-3">
                        <img src="https://s2.svgbox.net/materialui.svg?ic=email&amp;color=FFFFFF" alt="email" width="20" height="20" />
                    </button>
                    {!Object.keys(compareData?.items).includes(obj?.slug) ? (
                        <button type="button" onClick={() => addToCompare(obj)} className="btn btn-sm btn-primary">
                            Add to compare
                        </button>
                    ) : (
                        <button type="button" onClick={() => addToCompare(obj, "remove")} className="btn btn-sm btn-danger">
                            Remove from Compare
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    adminPackage: state.admin.admin.packages,
    compareData: state.compare,
});

export default connect(mapStateToProps, { getCompareData, setCompareData })(ServiceItem);
