import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import Form from "../../common/form";
import { clearAlerts } from "../../../actions/common";
import { getReviews, addReviews } from "../../../actions/plannerActions";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import "../../../styles/Review.css";
class Reviews extends Form {
    state = {
        data: {
            ref: this.props.refId,
            name: "",
            email: "",
            stars: 0,
            comment: "",
        },
        errors: {},
        openSubmitForm: false,
    };

    componentDidMount() {
        this.props.clearAlerts();
        (async () => await this.props.getReviews(this.state.data.ref))();
    }

    toggleForm(e) {
        const { openSubmitForm } = this.state;
        this.props.clearAlerts();
        this.setState({ openSubmitForm: !openSubmitForm });
        openSubmitForm && (async () => await this.props.getReviews(this.state.data.ref))();
    }

    doSubmit() {
        const { data } = this.state;
        this.props.clearAlerts();
        this.props.addReviews(data, this.props.history);
    }

    renderAddForm() {
        const { errorMsg, successMsg } = this.props;

        return (
            <div>
                <form className="rating-form" onSubmit={this.handleSubmit}>
                    <div class="form-group row">
                        <div class="col">
                            <input type="text" class="form-control" placeholder="Name" name="name" onChange={this.handleChange} />
                        </div>
                        <div class="col">
                            <input type="email" class="form-control" placeholder="Email" name="email" onChange={this.handleChange} />
                        </div>
                    </div>

                    <div class="form-group">
                        <textarea class="form-control" placeholder="Review" name="comment" rows="5" cols="50" onChange={this.handleChange}></textarea>
                    </div>

                    <div class="form-group">
                        <div class="form-control d-flex align-items-center">
                            <div class="mr-2 rating">
                                <input id="radio1" type="radio" name="stars" value="5" class="star" onChange={this.handleChange} />
                                <label for="radio1">&#9733;</label>
                                <input id="radio2" type="radio" name="stars" value="4" class="star" onChange={this.handleChange} />
                                <label for="radio2">&#9733;</label>
                                <input id="radio3" type="radio" name="stars" value="3" class="star" onChange={this.handleChange} />
                                <label for="radio3">&#9733;</label>
                                <input id="radio4" type="radio" name="stars" value="2" class="star" onChange={this.handleChange} />
                                <label for="radio4">&#9733;</label>
                                <input id="radio5" type="radio" name="star" value="1" class="star" onChange={this.handleChange} />
                                <label for="radio5">&#9733;</label>
                            </div>
                            click to rate on a scale of 5
                        </div>
                    </div>

                    <div class="form-group d-flex align-items-center justify-content-between">
                        <button className="btn btn-light" onClick={this.toggleForm.bind(this)}>
                            Back
                        </button>
                        <button type="submit" className="btn btn-primary">
                            Post Review
                        </button>
                    </div>
                    {errorMsg && <div class="mt-2 alert alert-danger">{errorMsg}</div>}
                    {successMsg && <div class="mt-2 alert alert-success">{successMsg}</div>}
                </form>
            </div>
        );
    }
    renderRatings(data) {
        const aggregateRating = data?.ratings?.average?.toFixed(1);
        return (
            <>
                <div class="heading">Ratings</div>
                <br />
                <div className="aggregate-rating">
                    <div className="uleft">
                        <div class="row">
                            <b>{aggregateRating}/5</b>
                        </div>
                        {this.renderStars(aggregateRating)}
                        <div class="row">
                            <p>{data?.ratings?.total} customer ratings.</p>
                        </div>
                    </div>
                    <div className="uright l-half">
                        {data?.ratings?.stars
                            .sort((a, b) => (a > b ? 1 : -1))
                            .map((itm, idx) => {
                                return (
                                    <div key={idx} class="row">
                                        <div class="side">
                                            {itm.star} <span class="fa fa-star"></span>
                                        </div>
                                        <div class="middle">
                                            <div class="bar-container">
                                                <div class="bar-5" style={{ width: `${(itm.count * 100) / data?.ratings?.total}%` }}></div>
                                            </div>
                                        </div>
                                        <div class="side right">
                                            <div>{itm.count}</div>
                                        </div>
                                    </div>
                                );
                            })}
                    </div>
                </div>
            </>
        );
    }
    renderReviews(itm, idx) {
        return (
            <div key={idx} className="comment">
                <div className="display-block">
                    <div className="uleft">
                        <b>{itm.name}</b>
                        <div className="verified">
                            <CheckCircleOutlineIcon fontSize="small" color="blue" />
                            <span>Verified</span>
                        </div>
                    </div>
                    <div className="uright">
                        <p>{new Date(itm.createdAt).toDateString()}</p>
                    </div>
                </div>
                <br />
                <div className="display-block">{this.renderStars(itm.stars)}</div>
                <div class="display-block">
                    <p>{itm.comment}</p>
                </div>
            </div>
        );
    }
    renderStars(num) {
        num = Math.min(Math.max(0, num), 5);
        return (
            <div class="row">
                <span class={`fa fa-star ${num >= 1 ? "checked" : ""}`}></span>
                <span class={`fa fa-star ${num >= 2 ? "checked" : ""}`}></span>
                <span class={`fa fa-star ${num >= 3 ? "checked" : ""}`}></span>
                <span class={`fa fa-star ${num >= 4 ? "checked" : ""}`}></span>
                <span class={`fa fa-star ${num >= 5 ? "checked" : ""}`}></span>
            </div>
        );
    }

    render() {
        const reviewCount = (this.props.fetchedData?.reviews || []).length;
        return (
            <div className="review-section">
                {this.state.openSubmitForm ? (
                    this.renderAddForm()
                ) : (
                    <div style={{ paddingBottom: "2%" }}>
                        <div className="reviews">
                            <button className="leave-review_button" onClick={this.toggleForm.bind(this)}>
                                Leave a review
                            </button>
                            {!!reviewCount && this.renderRatings(this.props.fetchedData)}
                        </div>
                        <div className="reviews">
                            <div class="heading">Reviews ({reviewCount})</div>
                        </div>
                        {this.props.fetchedData?.reviews?.map(this.renderReviews.bind(this))}
                    </div>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        errorMsg: state.common.errors,
        successMsg: state.common.success,
        fetchedData: state.planner.reviews || {},
    };
};

export default connect(mapStateToProps, { getReviews, addReviews, clearAlerts })(withRouter(Reviews));
