import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";
import { getCompareData, setCompareData } from "../../../../actions/compareActions";

const CompareList = ({ compareData, ...props }) => {
    const items = Object.values(compareData?.items || {});
    const [viewModal, setViewModal] = useState(false);
    const hideModal = () => setViewModal(false);
    const showModal = () => setViewModal(true);

    const emailAction = useCallback((item) => () => {
        window.location.href = `mailto:${item?.storeEmail || ""}?subject=Inquiry on ${item?.title || ""} services`;
    });

    const removeItem = useCallback((item) => () => {
        props.setCompareData("REMOVE", item);
    });

    const modalTitle = useCallback((market) => {
        let str = "Comparing";

        if (market === "up") str += " Upmarket";
        else if (market === "mix") str += " Marketmix";
        else if (market === "stream") str += " Mainstream";

        str += props?.area === "vendor" ? " Service" : " Package";
        return str;
    });

    const bgColor = useCallback((val) => {
        if (val === "up") return "#bf00ff";
        if (val === "mix") return "#32cd32";
        if (val === "stream") return "#ff3503";
        if (val === "Bronze") return "#e8ad71";
        if (val === "Silver") return "#C0C0C0";
        if (val === "Gold") return "#e3cb81";
        if (val === "Platinum") return "#e5e4e2";
        if (val === "Diamond") return "#bdd3e8";
        return "#8899aa";
    });

    useEffect(() => {
        const section = props?.area === "vendor" ? "service" : "package";
        if (section !== compareData.section) props.setCompareData("CLEAR_ALL");

        // hiding modal when nothing there
        if (!items.length) setTimeout(hideModal, 200);
    }, [compareData]);

    return (
        //aside-area, filter-title
        <div className="aside-area">
            {items.length ? (
                <button class="btn btn-block btn-primary mb-3 position-relative" onClick={showModal}>
                    View Compare
                    <span className="badge badge-light border-1-gray" style={{ position: "absolute", right: "-8px", top: "-8px" }}>
                        {items.length}
                    </span>
                </button>
            ) : (
                <button class="btn btn-block btn-secondary mb-3 disabled">View Compare</button>
            )}
            <div className="list-group mb-2">
                {items.map((itm, idx) => (
                    <a key={idx} className="list-group-item p-2 d-flex justify-content-between align-items-center" onClick={removeItem(itm)}>
                        {[itm.store, itm.title, itm.bundle].filter(Boolean).join(" - ")}
                        <span className="badge badge-danger">&times;</span>
                    </a>
                ))}
            </div>
            <Modal show={viewModal} onHide={hideModal} dialogClassName="modal-90w">
                <Modal.Header closeButton style={{ backgroundColor: bgColor(compareData?.market) }}>
                    <Modal.Title className="text-white">{modalTitle(compareData?.market)}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div class="card-deck text-center">
                        {items.map((itm, idx) => (
                            <div key={idx} class="col-md-3 card p-0 mb-2 shadow">
                                <div class="card-header bg-dark">
                                    <h4 class="my-0 font-weight-normal text-light">{itm.store}</h4>
                                </div>
                                <div class="card-body flex-grow-0 d-flex align-items-center justify-content-center" style={{ height: 120, backgroundColor: bgColor(itm.title) }}>
                                    <h3 class="card-title">{[itm.title, itm.bundle].filter(Boolean).join(" - ")}</h3>
                                </div>
                                <div class="card-body" style={{ height: 300, overflowY: "scroll", borderLeft: "8px solid #eee" }}>
                                    <p>{itm.info}</p>
                                </div>
                                <div class="card-footer d-flex align-items-center justify-content-between">
                                    {itm.price ? <span>{itm.price}</span> : ""}
                                    <a class="ml-3 btn btn-sm btn-warning" onClick={emailAction(itm)}>
                                        <img src="https://s2.svgbox.net/materialui.svg?ic=email&amp;color=FFFFFF" alt="email" />
                                    </a>
                                    <a class="ml-3 btn btn-sm btn-danger" onClick={removeItem(itm)}>
                                        <img src="https://s2.svgbox.net/materialui.svg?ic=close&amp;color=FFFFFF" alt="close" />
                                    </a>
                                </div>
                            </div>
                        ))}
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
};

const mapStateToProps = (state) => ({
    compareData: state.compare,
});
export default connect(mapStateToProps, { getCompareData, setCompareData })(CompareList);
