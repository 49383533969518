import React, { Component } from "react";
import { resetPassword } from "../../../actions/authActions";
import { connect } from "react-redux";
import classnames from "classnames";
import { Link, withRouter } from "react-router-dom";
import { resetResults, clearAlerts } from "../../../actions/common";
import Joi from "joi";
import Form from "../../common/form";
import isEmpty from "../../../utils/is-empty";
import Loader from "react-loader-spinner";

class ResetPassword extends Form {
    state = {
        data: { ...Object.fromEntries(new URLSearchParams(window.location.search)), newPassword: "", confirmPassword: "" },
        errors: {},
        backgroundImage: "",
    };

    schema = {
        newPassword: Joi.string().min(8).required().label("Password"),
        confirmPassword: Joi.string().valid(Joi.ref("newPassword")).label("Confirm Password").messages({ "any.only": "{{#label}} didn't match" }),
    };

    componentDidMount() {
        if (!isEmpty(this.props.authBg)) this.setState({ backgroundImage: this.props.authBg });
        this.props.clearAlerts();
    }

    componentWillReceiveProps = (nextProps) => {
        if (nextProps.errors) this.setState({ errors: nextProps.errors });
        if (nextProps.successMsg) this.props.history.push(`/login?successMsg=` + nextProps.successMsg);
    };

    handleChange = ({ target: input }) => {
        const { data } = { ...this.state };
        data[input.name] = input.value;
        this.setState({ data });
    };

    doSubmit = () => {
        const { data } = this.state;
        this.props.resetPassword(data);
    };

    render() {
        const { errors, data } = this.state;
        const { t, loading } = this.props;
        return (
            <section
                className="d-flex align-items-center flex-column justify-content-center h-100"
                style={{
                    backgroundImage: "url(" + this.props.authBg + ")",
                    backgroundPosition: "center",
                    opacity: 0.9,
                }}
            >
                <div className="regis-form login-form radius-5">
                    <div className="d-flex mb-20">
                        <h5>{t.resetPassword}</h5>
                    </div>
                    <form noValidate onSubmit={this.handleSubmit}>
                        <div className="form-group">
                            <input
                                type="password"
                                name="newPassword"
                                className={classnames("form-control", {
                                    "is-invalid": errors?.newPassword,
                                })}
                                placeholder="New Password"
                                onChange={this.handleChange}
                                value={data.newPassword}
                            />
                            {errors?.newPassword && <div className="invalid-feedback">{errors?.newPassword || ""}</div>}
                        </div>

                        <div className="form-group">
                            <input
                                type="password"
                                name="confirmPassword"
                                className={classnames("form-control", {
                                    "is-invalid": errors?.confirmPassword,
                                })}
                                placeholder="Confirm Password"
                                onChange={this.handleChange}
                                value={data.confirmPassword}
                            />
                            {errors?.confirmPassword && <div className="invalid-feedback">{errors?.confirmPassword || ""}</div>}
                        </div>
                        <div className="btn-wrap text-center">
                            <button type="auth" disabled={!this.validate() || loading} onClick={this.handleSubmit} className="btn btn-primary">
                                {loading && (
                                    <Loader
                                        type="ThreeDots"
                                        color="#00BFFF"
                                        height={40}
                                        width={40}
                                        //3 secs
                                    />
                                )}
                                {loading && "Submitting"}
                                {!loading && t.submit}
                            </button>
                        </div>
                    </form>
                </div>
            </section>
        );
    }
}

const mapStateToProps = (state) => ({
    errors: state.errors.errors,
    authBg: state.admin.admin.authBg,
    loading: state.common.loading,
    successMsg: state.common.success,
});

export default connect(mapStateToProps, { resetPassword, resetResults, clearAlerts })(withRouter(ResetPassword));
