import axios from "axios";
import setAuthToken from "../utils/setAuthToken";
import jwt_decode from "jwt-decode";
import { GET_ERRORS, GET_LOGIN_ERRORS, GET_REGISTER_ERRORS, SET_CURRENT_USER } from "../types/types";
import { setLoading, setLoadingFalse, setSuccessAlert } from "./common";

// Register User
export const registerUser = (userData, history) => (dispatch) => {
    axios
        .post("/api/users/register", userData)
        .then((res) => {
            axios.post("/api/users/login", userData).then((res) => {
                const { token } = res.data;

                // Set token to ls
                localStorage.setItem("jwtToken", token);

                // Set token to Auth header
                setAuthToken(token);
                // Decode token to get user data

                const decoded = jwt_decode(token);
                dispatch(setCurrentUser(decoded));
                if (decoded.type === "planner") {
                    history.push("/about-planner");
                } else {
                    history.push("/about-vendor");
                }
            });
        })
        .catch((err) =>
            dispatch({
                type: GET_REGISTER_ERRORS,
                payload: err.response.data,
            })
        );
};

// Login - Get User Token
export const loginUser = (userData, history) => (dispatch) => {
    dispatch(setLoading());
    axios
        .post("/api/users/login", userData)
        .then((res) => {
            // Save to localStorage
            const { token } = res.data;

            // Set token to ls
            localStorage.setItem("jwtToken", token);

            // Set token to Auth header
            setAuthToken(token);
            // Decode token to get user data

            const decoded = jwt_decode(token);

            // Set current user
            dispatch(setCurrentUser(decoded));
            dispatch(setLoadingFalse());
            if (decoded.type === "admin") {
                history.push("/dashboard/admin");
            } else if (decoded.type === "planner" || (decoded.type = "vendor")) {
                history.push("/dashboard-toggler");
            }
        })
        .catch((err) => {
            dispatch(setLoadingFalse());
            dispatch({
                type: GET_LOGIN_ERRORS,
                payload: err.response.data,
            });
        });
};

export const forgotUser = (userData) => (dispatch) => {
    dispatch(setLoading());
    axios
        .post("/api/users/forgot", userData)
        .then((res) => {
            if (!res.data.success) throw new Error(res.data.message);

            console.log(res.data.message);

            dispatch(setLoadingFalse());
            dispatch(setSuccessAlert(res.data.message));
        })
        .catch((err) => {
            console.error(err);
            dispatch(setLoadingFalse());
            dispatch({
                type: GET_ERRORS,
                payload: { email: err.response.data.error },
            });
        });
};

export const resetPassword = (userData, history) => (dispatch) => {
    if (userData.newPassword !== userData.confirmPassword) {
        dispatch({
            type: GET_ERRORS,
            payload: { confirmPassword: "Confirm password should be same" },
        });
    } else {
        dispatch(setLoading());
        axios
            .post("/api/users/resetPass", userData)
            .then(({ data }) => {
                if (!data.success) throw new Error(data.error);
                dispatch(setLoadingFalse());
                dispatch(setSuccessAlert(data.message));
            })
            .catch((err) => {
                dispatch(setLoadingFalse());
                dispatch({
                    type: GET_ERRORS,
                    payload: { confirmPassword: err.response.data.error },
                });
            });
    }
};

// Set logged in user
export const setCurrentUser = (decoded) => {
    return {
        type: SET_CURRENT_USER,
        payload: decoded,
    };
};

// Log user out
export const logoutUser = () => (dispatch) => {
    // Remove token from localStorage
    localStorage.removeItem("jwtToken");
    // Remove auth header for future requests
    setAuthToken(false);
    // Set current user to {} which will set isAuthenticated to false
    dispatch(setCurrentUser({}));
};
