import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

const SelectListGroup = ({ name, value, error, info, onChange, options, disabled }) => {
    const selectOptions = options.map((option) => (
        <option key={option.label} value={option.value} selected="true">
            {option.label}
        </option>
    ));
    return (
        <div className="">
            <select
                className={classnames("form-control", {
                    "is-invalid": error,
                })}
                name={name}
                value={value}
                onChange={onChange}
                disabled={disabled}
            >
                {selectOptions}
            </select>
            {info && <small className="form-text text-muted">{info}</small>}
            {error && <div className="invalid-feedback">{error}</div>}
        </div>
    );
};

SelectListGroup.propTypes = {
    name: PropTypes.string.isRequired,

    info: PropTypes.string,
    error: PropTypes.string,

    options: PropTypes.array.isRequired,
};

export default SelectListGroup;
