import React, { Component, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { RMIUploader } from "react-multiple-image-uploader";
import axios from "axios";
import Spinner from "../../common/Spinner/Spinner";
import { addPlannerImages, removePlannerImages } from "../../../actions/plannerActions";
import { addVendorImages, removeVendorImages } from "../../../actions/vendorActions";
class UploadImages extends Component {
    state = {
        minReq: { width: 1024, height: 576, ratio: 16 / 9 },
        visible: false,
        loading: false,
        dataSources: [{ id: 1, dataURL: "https://picsum.photos/seed/5/600" }],
    };

    componentDidMount() {
        this.clearState();
    }

    componentWillReceiveProps(nextProps) {
        this.clearState(nextProps);
    }

    clearState = (props) => {
        props = props || this.props;
        const images = props.profile.images;

        const dataSources = Object.keys(images)
            .filter(Boolean)
            .map((key) => ({ id: key, dataURL: images[key] }));
        this.setState({ loading: false, visible: false, dataSources });
    };

    validateImage = (file, next) => {
        const { minReq } = this.state;
        const img = new Image();
        img.src = window.URL.createObjectURL(file);
        img.onload = () => {
            if (img.width < minReq.width || img.height < minReq.height) {
                next(`Image size should be at least ${this.state.minW} x ${this.state.minh}.`);
                return;
            }

            const aspectRatio = img.width / img.height;
            if (!(Math.floor(minReq.ratio) < aspectRatio && aspectRatio < Math.ceil(minReq.ratio))) {
                next(`Image aspect ratio must be 16:9`);
                return;
            }

            next();
            return;
        };
    };

    toServer = (file, idx) => {
        const fileId = `image${idx + 1}`;
        const formData = new FormData();
        formData.append(fileId, file);

        this.setState({ loading: true });
        axios
            .post(`/api/image-upload/${fileId}`, formData, { timeout: 30000 })
            .then(({ data }) => {
                const { dataSources } = this.state;
                dataSources.push({ id: idx, dataURL: data.imageUrl });
                this.setState({ dataSources });

                toast.success("Image Uploaded Successfully");
            })
            .catch((err) => {
                toast.error("Failed to Upload Image. Exceeded Time Duration");
            })
            .finally(() => this.setState({ loading: false }));
    };

    handleSetVisible = () => {
        this.setState({ visible: true });
    };
    hideModal = () => {
        this.setState({ visible: false });
    };
    onUpload = (data) => {
        console.log("Upload files", data);

        data.forEach((itm, idx) => {
            this.validateImage(itm.file, (err) => {
                if (err) {
                    toast.error(err);
                } else {
                    this.toServer(itm.file, idx);
                }
            });
        });
    };
    onSelect = (data) => {
        if (data.length > 5) {
            toast.error("You can select max to 5 images.");
            return;
        }

        this.setState({ loading: true });

        const imageFields = {};
        data.slice(0, 5).forEach((itm, idx) => {
            imageFields[`image${idx + 1}`] = itm.dataURL;
        });

        const { type } = this.props.auth.user;
        if (type === "planner") {
            this.props.addPlannerImages(imageFields);
        } else if (type === "vendor") {
            this.props.addVendorImages(imageFields);
        }

        toast.success("Successfully updated on website");
        this.clearState();
    };
    onRemove = (id) => {
        this.setState({ loading: true });

        const imageFields = { [id]: null };
        const { type } = this.props.auth.user;
        if (type === "planner") {
            this.props.removePlannerImages(imageFields);
        } else if (type === "vendor") {
            this.props.removeVendorImages(imageFields);
        }

        toast.success("Successfully removed from website");
        this.clearState();
    };

    render() {
        if (this.state.loading) return <Spinner />;
        return (
            <>
                <h5 className="">Upload Images</h5>
                <p className="text-muted">
                    Minimum Size: <code className="font-weight-bold">{this.state.minReq.width}px</code> &times; <code className="font-weight-bold">{this.state.minReq.height}px</code> &nbsp; Required Aspect Ratio: <code className="font-weight-bold">16:9</code>
                </p>
                <RMIUploader isOpen={this.state.visible} hideModal={this.hideModal} onSelect={this.onSelect} onUpload={this.onUpload} onRemove={this.onRemove} dataSources={this.state.dataSources} />
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    profile: state.profile.profile,
    t: state.common.dashboardLabels,
});

export default connect(mapStateToProps, { addPlannerImages, addVendorImages, removePlannerImages, removeVendorImages })(UploadImages);
