const isObject = (obj) => String(obj) === String({}) && Object.keys(obj).length > 0;
const initialState = { items: {}, error: null, market: null, section: null };
const MAX_COMPARE_ITEMS = 5;

export default function (state = initialState, { type, payload }) {
    state = { ...state, ...JSON.parse(localStorage.getItem("comparisons")) };
    const item = payload?.item && isObject(payload?.item) ? { ...payload?.item } : null;
    const action = String(payload?.action).toUpperCase();

    switch (type) {
        case "SET_COMPARE_DATA":
            // clear-all
            if (action === "CLEAR_ALL" || item === null) {
                state = initialState;
            }

            // remove-item
            else if (action === "REMOVE") {
                delete state.items[item.slug];
                state.error = null;

                // no items, then clear-all
                if (Object.keys(state.items).length <= 0) {
                    state = initialState;
                }
            }

            // push-item
            else if (state.market === item.market && isObject(state.items)) {
                // insert only, if there is no error
                if (!state.error) state.items[item.slug] = item;

                // check for max-limit
                state.error = Object.values(state.items).length < MAX_COMPARE_ITEMS ? null : "Max limit reached for comparison";
            }

            // add-item
            else {
                state.items = { [item?.slug]: item };
                state.error = null;
                state.market = item.market;
                state.section = item.section;
            }
            break;

        case "GET_COMPARE_DATA":
            break;
        default:
    }

    localStorage.setItem("comparisons", JSON.stringify(state));
    return state;
}
