import React, { Component } from "react";
import Joi from "joi";
// import Input from "./input";
// import Select from "./select";

class Form extends Component {
    state = {
        data: {},
        errors: {},
    };

    schema = {};

    validate = (setState = false) => {
        const { error } = Joi.object().keys(this.schema).unknown(true).validate(this.state.data);
        if (!error) {
            setState && this.setState({ errors: {} });
            return true;
        }

        const errors = {};
        error?.details.map((itm) => {
            errors[itm.path[0]] = itm.message;
        });
        setState && this.setState({ errors });
        return false;
    };

    validateProperty = ({ name, value }) => {
        const data = { [name]: value };
        const schema = { [name]: this.schema[name] };

        return Joi.object().keys(schema).validate(data);
    };

    handleChange = ({ target: input }) => {
        const { data, errors } = { ...this.state };

        if (this.schema.hasOwnProperty(input.name)) {
            const { error } = this.validateProperty(input);
            if (error) errors[input.name] = error.message;
            else delete errors[input.name];
        }

        data[input.name] = input.value;
        this.setState({ data, errors });
    };

    handleOptional = ({ target: input }) => {
        const optional = { ...this.state.optional };
        optional[input.name] = input.value;
        this.setState({ optional });
    };

    handleSubmit = (e) => {
        e.preventDefault();

        const isValid = this.validate(true);
        if (isValid) this.doSubmit();
    };

    //   renderButton(label) {
    //     return (
    //       <button disabled={this.validate()} className="btn btn-primary">
    //         {label}
    //       </button>
    //     );
    //   }

    //   renderSelect(name, label, options) {
    //     const { data, errors } = this.state;

    //     return (
    //       <Select
    //         name={name}
    //         value={data[name]}
    //         label={label}
    //         options={options}
    //         onChange={this.handleChange}
    //         error={errors[name]}
    //       />
    //     );
    //   }

    //   renderInput(name, label, type = "text") {
    //     const { data, errors } = this.state;

    //     return (
    //       <Input
    //         type={type}
    //         name={name}
    //         value={data[name]}
    //         label={label}
    //         onChange={this.handleChange}
    //         error={errors[name]}
    //       />
    //     );
    //   }
}

export default Form;
