import React, { Component } from "react";
import { connect } from "react-redux";
import { getPlannerCount, getVendorCount, getBlockPlannerCount, getBlockVendorCount } from "../../../actions/adminActions";

class UserStats extends Component {
    componentDidMount = () => {
        this.props.getVendorCount();
        this.props.getPlannerCount();
        this.props.getBlockVendorCount();
        this.props.getBlockPlannerCount();
    };

    render() {
        const { vendorCount, plannerCount, blockVendorCount, blockPlannerCount } = this.props;

        return (
            <div className="tab-pane" id="services" role="tabpanel">
                <div className="row">
                    <div style={{ paddingBottom: "30px" }} className="col-md-12 text-center">
                        <h4 className="font-32 tabs-title">Registered Users</h4>
                    </div>
                    <div className="col-md-12 text-center">
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <div className="col-md-8 text-center">
                                <h5 style={{ color: "green" }}>Event Planners</h5>
                            </div>
                            <div className="col-md-4">
                                <h5 style={{ color: "green" }}>{plannerCount || 0}</h5>
                            </div>
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <div className="col-md-8 text-center">
                                <h5 style={{ color: "blue" }}>Event Vendors</h5>
                            </div>
                            <div className="col-md-4">
                                <h5 style={{ color: "blue" }}>{vendorCount || 0}</h5>
                            </div>
                        </div>
                        <div className="bd-top" />
                        <div style={{ display: "flex" }}>
                            <div className="col-md-8 text-center">
                                <h4>Total</h4>
                            </div>
                            <div className="col-md-4">
                                <h4>{Number(plannerCount + vendorCount) || 0}</h4>
                            </div>
                        </div>
                        <br />
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <div className="col-md-8 text-center">
                                <h5 style={{ color: "red" }}>Blocked Planners</h5>
                            </div>
                            <div className="col-md-4">
                                <h5 style={{ color: "red" }}>{blockPlannerCount || 0}</h5>
                            </div>
                        </div>{" "}
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <div className="col-md-8 text-center">
                                <h5 style={{ color: "red" }}>Blocked Vendors</h5>
                            </div>
                            <div className="col-md-4">
                                <h5 style={{ color: "red" }}>{blockVendorCount || 0}</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    vendorCount: state.admin.vendorCount,
    plannerCount: state.admin.plannerCount,
    blockVendorCount: state.admin.blockVendorCount,
    blockPlannerCount: state.admin.blockPlannerCount,
});

export default connect(mapStateToProps, {
    getVendorCount,
    getPlannerCount,
    getBlockVendorCount,
    getBlockPlannerCount,
})(UserStats);
