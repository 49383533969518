import React, { Component } from "react";
import axios from "axios";
import { toast } from "react-toastify";

class ImageForm extends React.Component {
    state = {
        data: {},
    };

    onImageSelected = (e) => {
        const id = e.target.id;
        const file = e.target.files[0];

        const minReq = { width: 1024, height: 576, ratio: 16 / 9 };
        const img = new Image();
        img.src = window.URL.createObjectURL(file);
        img.onload = (x = 0, y = file, i = id) => {
            if (img.width < minReq.width || img.height < minReq.height) {
                toast.warn(`Image size should be at least ${minReq.width} x ${minReq.height}.`);
                return;
            }

            const aspectRatio = img.width / img.height;
            if (!(Math.floor(minReq.ratio) < aspectRatio && aspectRatio < Math.ceil(minReq.ratio))) {
                toast.warn(`Image aspect ratio must be 16:9`);
                return;
            }

            document.getElementById(id + "label").innerHTML = file.name;
            this.setState({
                data: {
                    ...this.state.data,
                    selectedFile: y,
                    selected: true,
                },
            });
            return;
        };
    };

    onImageUpload = (e) => {
        e.preventDefault();
        const { selected } = this.state.data;
        if (!selected) {
            toast.warn("Please Select a image to upload");
        } else {
            this.setState({ data: { ...this.state.data, loading: true } });
            let id = e.target.id;
            const httpClient = axios.create();
            httpClient.defaults.timeout = 30000;
            const formData = new FormData();
            formData.append(id, this.state.data.selectedFile);

            httpClient
                .post(`/api/image-upload/${id}`, formData)
                .then((res) => this.onSuccess(res, id))
                .catch((err) => this.onFailure(err));
        }
    };

    onSuccess = (res, id) => {
        toast.success("Image Uploaded Successfully");
        this.setState(
            {
                data: {
                    ...this.state.data,
                    [id]: res.data.imageUrl,
                    selectedFile: {},
                    loading: false,
                },
            },
            () => {
                if (id !== "noticeboard") this.onSubmit();
            }
        );
    };

    onFailure = (err) => {
        this.setState({
            data: { ...this.state.data, selectedFile: {}, loading: false },
        });
        toast.error("Failed to Upload Image. Exceeded Time Duration");
    };
}

export default ImageForm;
