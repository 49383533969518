const countryData = [
    { id: 1, code: "AF", name: "Afghanistan", continent: "Asia", phoneCode: 93 },
    { id: 10, code: "AR", name: "Argentina", continent: "South America", phoneCode: 54 },
    { id: 100, code: "IS", name: "Iceland", continent: "Europe", phoneCode: 354 },
    { id: 101, code: "IN", name: "India", continent: "Asia", phoneCode: 91 },
    { id: 102, code: "ID", name: "Indonesia", continent: "Asia", phoneCode: 62 },
    { id: 103, code: "IR", name: "Iran", continent: "Asia", phoneCode: 98 },
    { id: 104, code: "IQ", name: "Iraq", continent: "Asia", phoneCode: 964 },
    { id: 105, code: "IE", name: "Ireland", continent: "Europe", phoneCode: 353 },
    { id: 106, code: "IL", name: "Israel", continent: "Asia", phoneCode: 972 },
    { id: 107, code: "IT", name: "Italy", continent: "Europe", phoneCode: 39 },
    { id: 109, code: "JP", name: "Japan", continent: "Asia", phoneCode: 81 },
    { id: 11, code: "AM", name: "Armenia", continent: "Asia", phoneCode: 374 },
    { id: 111, code: "JO", name: "Jordan", continent: "Asia", phoneCode: 962 },
    { id: 112, code: "KZ", name: "Kazakhstan", continent: "Asia", phoneCode: 7 },
    { id: 113, code: "KE", name: "Kenya", continent: "Africa", phoneCode: 254 },
    { id: 114, code: "KI", name: "Kiribati", continent: "Oceania", phoneCode: 686 },
    { id: 115, code: "KP", name: "Korea North", continent: "Asia", phoneCode: 850 },
    { id: 116, code: "KR", name: "Korea South", continent: "Asia", phoneCode: 82 },
    { id: 117, code: "KW", name: "Kuwait", continent: "Asia", phoneCode: 965 },
    { id: 118, code: "KG", name: "Kyrgyzstan", continent: "Asia", phoneCode: 996 },
    { id: 119, code: "LA", name: "Laos", continent: "Asia", phoneCode: 856 },
    { id: 120, code: "LV", name: "Latvia", continent: "Europe", phoneCode: 371 },
    { id: 121, code: "LB", name: "Lebanon", continent: "Asia", phoneCode: 961 },
    { id: 122, code: "LS", name: "Lesotho", continent: "Africa", phoneCode: 266 },
    { id: 123, code: "LR", name: "Liberia", continent: "Africa", phoneCode: 231 },
    { id: 124, code: "LY", name: "Libya", continent: "Africa", phoneCode: 218 },
    { id: 125, code: "LI", name: "Liechtenstein", continent: "Europe", phoneCode: 423 },
    { id: 126, code: "LT", name: "Lithuania", continent: "Europe", phoneCode: 370 },
    { id: 127, code: "LU", name: "Luxembourg", continent: "Europe", phoneCode: 352 },
    { id: 128, code: "MO", name: "Macau S.A.R.", continent: "Asia", phoneCode: 853 },
    { id: 129, code: "MK", name: "Macedonia", continent: "Europe", phoneCode: 389 },
    { id: 13, code: "AU", name: "Australia", continent: "Oceania", phoneCode: 61 },
    { id: 130, code: "MG", name: "Madagascar", continent: "Africa", phoneCode: 261 },
    { id: 131, code: "MW", name: "Malawi", continent: "Africa", phoneCode: 265 },
    { id: 132, code: "MY", name: "Malaysia", continent: "Asia", phoneCode: 60 },
    { id: 133, code: "MV", name: "Maldives", continent: "Asia", phoneCode: 960 },
    { id: 134, code: "ML", name: "Mali", continent: "Africa", phoneCode: 223 },
    { id: 135, code: "MT", name: "Malta", continent: "Europe", phoneCode: 356 },
    { id: 137, code: "MH", name: "Marshall Islands", continent: "Oceania", phoneCode: 692 },
    { id: 139, code: "MR", name: "Mauritania", continent: "Africa", phoneCode: 222 },
    { id: 14, code: "AT", name: "Austria", continent: "Europe", phoneCode: 43 },
    { id: 140, code: "MU", name: "Mauritius", continent: "Africa", phoneCode: 230 },
    { id: 141, code: "YT", name: "Mayotte", continent: "Africa", phoneCode: 269 },
    { id: 143, code: "FM", name: "Micronesia", continent: "Oceania", phoneCode: 691 },
    { id: 144, code: "MD", name: "Moldova", continent: "Europe", phoneCode: 373 },
    { id: 145, code: "MC", name: "Monaco", continent: "Europe", phoneCode: 377 },
    { id: 146, code: "MN", name: "Mongolia", continent: "Asia", phoneCode: 976 },
    { id: 148, code: "MA", name: "Morocco", continent: "Africa", phoneCode: 212 },
    { id: 149, code: "MZ", name: "Mozambique", continent: "Africa", phoneCode: 258 },
    { id: 15, code: "AZ", name: "Azerbaijan", continent: "Asia", phoneCode: 994 },
    { id: 150, code: "MM", name: "Myanmar", continent: "Asia", phoneCode: 95 },
    { id: 151, code: "NA", name: "Namibia", continent: "Africa", phoneCode: 264 },
    { id: 152, code: "NR", name: "Nauru", continent: "Oceania", phoneCode: 674 },
    { id: 153, code: "NP", name: "Nepal", continent: "Asia", phoneCode: 977 },
    { id: 155, code: "NL", name: "Netherlands", continent: "Europe", phoneCode: 31 },
    { id: 156, code: "NC", name: "New Caledonia", continent: "Oceania", phoneCode: 687 },
    { id: 157, code: "NZ", name: "New Zealand", continent: "Oceania", phoneCode: 64 },
    { id: 159, code: "NE", name: "Niger", continent: "Africa", phoneCode: 227 },
    { id: 160, code: "NG", name: "Nigeria", continent: "Africa", phoneCode: 234 },
    { id: 161, code: "NU", name: "Niue", continent: "Oceania", phoneCode: 683 },
    { id: 162, code: "NF", name: "Norfolk Island", continent: "Oceania", phoneCode: 672 },
    { id: 163, code: "MP", name: "Northern Mariana Islands", phoneCode: 1670, continent: "Oceania" },
    { id: 164, code: "NO", name: "Norway", continent: "Europe", phoneCode: 47 },
    { id: 165, code: "OM", name: "Oman", continent: "Asia", phoneCode: 968 },
    { id: 166, code: "PK", name: "Pakistan", continent: "Asia", phoneCode: 92 },
    { id: 167, code: "PW", name: "Palau", continent: "Oceania", phoneCode: 680 },
    { id: 168, code: "PS", name: "Palestinian Territory Occupied", continent: "Asia", phoneCode: 970 },
    { id: 17, code: "BH", name: "Bahrain", continent: "Asia", phoneCode: 973 },
    { id: 170, code: "PG", name: "Papua new Guinea", continent: "Oceania", phoneCode: 675 },
    { id: 171, code: "PY", name: "Paraguay", continent: "South America", phoneCode: 595 },
    { id: 172, code: "PE", name: "Peru", continent: "South America", phoneCode: 51 },
    { id: 173, code: "PH", name: "Philippines", continent: "Asia", phoneCode: 63 },
    { id: 174, code: "PN", name: "Pitcairn Island", continent: "Oceania", phoneCode: 0 },
    { id: 175, code: "PL", name: "Poland", continent: "Europe", phoneCode: 48 },
    { id: 176, code: "PT", name: "Portugal", continent: "Europe", phoneCode: 351 },
    { id: 178, code: "QA", name: "Qatar", continent: "Asia", phoneCode: 974 },
    { id: 179, code: "RE", name: "Reunion", continent: "Africa", phoneCode: 262 },
    { id: 18, code: "BD", name: "Bangladesh", continent: "Asia", phoneCode: 880 },
    { id: 180, code: "RO", name: "Romania", continent: "Europe", phoneCode: 40 },
    { id: 181, code: "RU", name: "Russia", continent: "Europe", phoneCode: 70 },
    { id: 182, code: "RW", name: "Rwanda", continent: "Africa", phoneCode: 250 },
    { id: 183, code: "SH", name: "Saint Helena", continent: "Africa", phoneCode: 290 },
    { id: 188, code: "WS", name: "Samoa", continent: "Oceania", phoneCode: 684 },
    { id: 189, code: "SM", name: "San Marino", continent: "Europe", phoneCode: 378 },
    { id: 190, code: "ST", name: "Sao Tome and Principe", continent: "Africa", phoneCode: 239 },
    { id: 191, code: "SA", name: "Saudi Arabia", continent: "Africa", phoneCode: 966 },
    { id: 192, code: "SN", name: "Senegal", continent: "Africa", phoneCode: 221 },
    { id: 193, code: "RS", name: "Serbia", continent: "Europe", phoneCode: 381 },
    { id: 194, code: "SC", name: "Seychelles", continent: "Africa", phoneCode: 248 },
    { id: 195, code: "SL", name: "Sierra Leone", continent: "Africa", phoneCode: 232 },
    { id: 196, code: "SG", name: "Singapore", continent: "Asia", phoneCode: 65 },
    { id: 197, code: "SK", name: "Slovakia", continent: "Europe", phoneCode: 421 },
    { id: 198, code: "SI", name: "Slovenia", continent: "Europe", phoneCode: 386 },
    { id: 199, code: "XG", name: "Smaller Territories of the UK", continent: "Europe", phoneCode: 44 },
    { id: 2, code: "AL", name: "Albania", continent: "Europe", phoneCode: 355 },
    { id: 20, code: "BY", name: "Belarus", continent: "Europe", phoneCode: 375 },
    { id: 200, code: "SB", name: "Solomon Islands", continent: "Oceania", phoneCode: 677 },
    { id: 201, code: "SO", name: "Somalia", continent: "Africa", phoneCode: 252 },
    { id: 202, code: "ZA", name: "South Africa", continent: "Africa", phoneCode: 27 },
    { id: 203, code: "GS", name: "South Georgia", continent: "Antarctica", phoneCode: 0 },
    { id: 204, code: "SS", name: "South Sudan", continent: "Africa", phoneCode: 211 },
    { id: 205, code: "ES", name: "Spain", continent: "Europe", phoneCode: 34 },
    { id: 206, code: "LK", name: "Sri Lanka", continent: "Asia", phoneCode: 94 },
    { id: 207, code: "SD", name: "Sudan", continent: "Africa", phoneCode: 249 },
    { id: 208, code: "SR", name: "Suriname", continent: "South America", phoneCode: 597 },
    { id: 209, code: "SJ", name: "Svalbard And Jan Mayen Islands", continent: "Europe", phoneCode: 47 },
    { id: 21, code: "BE", name: "Belgium", continent: "Europe", phoneCode: 32 },
    { id: 210, code: "SZ", name: "Swaziland", continent: "Africa", phoneCode: 268 },
    { id: 211, code: "SE", name: "Sweden", continent: "Europe", phoneCode: 46 },
    { id: 212, code: "CH", name: "Switzerland", continent: "Europe", phoneCode: 41 },
    { id: 213, code: "SY", name: "Syria", continent: "Asia", phoneCode: 963 },
    { id: 214, code: "TW", name: "Taiwan", continent: "Asia", phoneCode: 886 },
    { id: 215, code: "TJ", name: "Tajikistan", continent: "Asia", phoneCode: 992 },
    { id: 216, code: "TZ", name: "Tanzania", continent: "Africa", phoneCode: 255 },
    { id: 217, code: "TH", name: "Thailand", continent: "Asia", phoneCode: 66 },
    { id: 218, code: "TG", name: "Togo", continent: "Africa", phoneCode: 228 },
    { id: 219, code: "TK", name: "Tokelau", continent: "Oceania", phoneCode: 690 },
    { id: 220, code: "TO", name: "Tonga", continent: "Oceania", phoneCode: 676 },
    { id: 222, code: "TN", name: "Tunisia", continent: "Africa", phoneCode: 216 },
    { id: 223, code: "TR", name: "Turkey", continent: "Asia", phoneCode: 90 },
    { id: 224, code: "TM", name: "Turkmenistan", continent: "Asia", phoneCode: 7370 },
    { id: 226, code: "TV", name: "Tuvalu", continent: "Oceania", phoneCode: 688 },
    { id: 227, code: "UG", name: "Uganda", continent: "Africa", phoneCode: 256 },
    { id: 228, code: "UA", name: "Ukraine", continent: "Europe", phoneCode: 380 },
    { id: 229, code: "AE", name: "United Arab Emirates", continent: "Asia", phoneCode: 971 },
    { id: 23, code: "BJ", name: "Benin", continent: "Africa", phoneCode: 229 },
    { id: 230, code: "GB", name: "United Kingdom", continent: "Europe", phoneCode: 44 },
    { id: 232, code: "UM", name: "United States Minor Outlying Islands", continent: "Oceania", phoneCode: 1 },
    { id: 233, code: "UY", name: "Uruguay", continent: "South America", phoneCode: 598 },
    { id: 234, code: "UZ", name: "Uzbekistan", continent: "Asia", phoneCode: 998 },
    { id: 235, code: "VU", name: "Vanuatu", continent: "Oceania", phoneCode: 678 },
    { id: 236, code: "VA", name: "Vatican City State", continent: "Europe", phoneCode: 39 },
    { id: 237, code: "VE", name: "Venezuela", continent: "South America", phoneCode: 58 },
    { id: 238, code: "VN", name: "Vietnam", continent: "Asia", phoneCode: 84 },
    { id: 241, code: "WF", name: "Wallis And Futuna Islands", continent: "Oceania", phoneCode: 681 },
    { id: 242, code: "EH", name: "Western Sahara", continent: "Africa", phoneCode: 212 },
    { id: 243, code: "YE", name: "Yemen", continent: "Asia", phoneCode: 967 },
    { id: 244, code: "YU", name: "Yugoslavia", continent: "Europe", phoneCode: 38 },
    { id: 245, code: "ZM", name: "Zambia", continent: "Africa", phoneCode: 260 },
    { id: 246, code: "ZW", name: "Zimbabwe", continent: "Africa", phoneCode: 26 },
    { id: 25, code: "BT", name: "Bhutan", continent: "Asia", phoneCode: 975 },
    { id: 26, code: "BO", name: "Bolivia", continent: "South America", phoneCode: 591 },
    { id: 27, code: "BA", name: "Bosnia and Herzegovina", continent: "Europe", phoneCode: 387 },
    { id: 28, code: "BW", name: "Botswana", continent: "Africa", phoneCode: 267 },
    { id: 29, code: "BV", name: "Bouvet Island", continent: "Antarctica", phoneCode: 0 },
    { id: 3, code: "DZ", name: "Algeria", continent: "Africa", phoneCode: 213 },
    { id: 30, code: "BR", name: "Brazil", continent: "South America", phoneCode: 55 },
    { id: 31, code: "IO", name: "British Indian Ocean Territory", continent: "Africa", phoneCode: 246 },
    { id: 32, code: "BN", name: "Brunei", continent: "Asia", phoneCode: 673 },
    { id: 33, code: "BG", name: "Bulgaria", continent: "Europe", phoneCode: 359 },
    { id: 34, code: "BF", name: "Burkina Faso", continent: "Africa", phoneCode: 226 },
    { id: 35, code: "BI", name: "Burundi", continent: "Africa", phoneCode: 257 },
    { id: 36, code: "KH", name: "Cambodia", continent: "Asia", phoneCode: 855 },
    { id: 37, code: "CM", name: "Cameroon", continent: "Africa", phoneCode: 237 },
    { id: 39, code: "CV", name: "Cape Verde", continent: "Africa", phoneCode: 238 },
    { id: 4, code: "AS", name: "American Samoa", continent: "Oceania", phoneCode: 1684 },
    { id: 41, code: "CF", name: "Central African Republic", continent: "Africa", phoneCode: 236 },
    { id: 42, code: "TD", name: "Chad", continent: "Africa", phoneCode: 235 },
    { id: 43, code: "CL", name: "Chile", continent: "South America", phoneCode: 56 },
    { id: 44, code: "CN", name: "China", continent: "Asia", phoneCode: 86 },
    { id: 45, code: "CX", name: "Christmas Island", continent: "Oceania", phoneCode: 61 },
    { id: 46, code: "CC", name: "Cocos (Keeling) Islands", continent: "Oceania", phoneCode: 672 },
    { id: 47, code: "CO", name: "Colombia", continent: "South America", phoneCode: 57 },
    { id: 48, code: "KM", name: "Comoros", continent: "Africa", phoneCode: 269 },
    { id: 49, code: "CG", name: "Republic Of The Congo", continent: "Africa", phoneCode: 242 },
    { id: 5, code: "AD", name: "Andorra", continent: "Europe", phoneCode: 376 },
    { id: 50, code: "CD", name: "Democratic Republic Of The Congo", continent: "Africa", phoneCode: 242 },
    { id: 51, code: "CK", name: "Cook Islands", continent: "Oceania", phoneCode: 682 },
    { id: 54, code: "HR", name: "Croatia (Hrvatska)", continent: "Europe", phoneCode: 385 },
    { id: 56, code: "CY", name: "Cyprus", continent: "Asia", phoneCode: 357 },
    { id: 57, code: "CZ", name: "Czech Republic", continent: "Europe", phoneCode: 420 },
    { id: 58, code: "DK", name: "Denmark", continent: "Europe", phoneCode: 45 },
    { id: 59, code: "DJ", name: "Djibouti", continent: "Africa", phoneCode: 253 },
    { id: 6, code: "AO", name: "Angola", continent: "Africa", phoneCode: 244 },
    { id: 62, code: "TP", name: "East Timor", continent: "Asia", phoneCode: 670 },
    { id: 63, code: "EC", name: "Ecuador", continent: "South America", phoneCode: 593 },
    { id: 64, code: "EG", name: "Egypt", continent: "Africa", phoneCode: 20 },
    { id: 66, code: "GQ", name: "Equatorial Guinea", continent: "Africa", phoneCode: 240 },
    { id: 67, code: "ER", name: "Eritrea", continent: "Africa", phoneCode: 291 },
    { id: 68, code: "EE", name: "Estonia", continent: "Europe", phoneCode: 372 },
    { id: 69, code: "ET", name: "Ethiopia", continent: "Africa", phoneCode: 251 },
    { id: 71, code: "FK", name: "Falkland Islands", continent: "South America", phoneCode: 500 },
    { id: 72, code: "FO", name: "Faroe Islands", continent: "Europe", phoneCode: 298 },
    { id: 73, code: "FJ", name: "Fiji Islands", continent: "Oceania", phoneCode: 679 },
    { id: 74, code: "FI", name: "Finland", continent: "Europe", phoneCode: 358 },
    { id: 75, code: "FR", name: "France", continent: "Europe", phoneCode: 33 },
    { id: 76, code: "GF", name: "French Guiana", continent: "South America", phoneCode: 594 },
    { id: 77, code: "PF", name: "French Polynesia", continent: "Oceania", phoneCode: 689 },
    { id: 78, code: "TF", name: "French Southern Territories", continent: "Antarctica", phoneCode: 0 },
    { id: 79, code: "GA", name: "Gabon", continent: "Africa", phoneCode: 241 },
    { id: 8, code: "AQ", name: "Antarctica", continent: "Antarctica", phoneCode: 0 },
    { id: 80, code: "GM", name: "Gambia", continent: "Africa", phoneCode: 220 },
    { id: 81, code: "GE", name: "Georgia", continent: "Asia", phoneCode: 995 },
    { id: 82, code: "DE", name: "Germany", continent: "Europe", phoneCode: 49 },
    { id: 83, code: "GH", name: "Ghana", continent: "Africa", phoneCode: 233 },
    { id: 84, code: "GI", name: "Gibraltar", continent: "Europe", phoneCode: 350 },
    { id: 85, code: "GR", name: "Greece", continent: "Europe", phoneCode: 30 },
    { id: 89, code: "GU", name: "Guam", continent: "Oceania", phoneCode: 1671 },
    { id: 92, code: "GN", name: "Guinea", continent: "Africa", phoneCode: 224 },
    { id: 93, code: "GW", name: "Guinea-Bissau", continent: "Africa", phoneCode: 245 },
    { id: 94, code: "GY", name: "Guyana", continent: "South America", phoneCode: 592 },
    { id: 96, code: "HM", name: "Heard and McDonald Islands", continent: "Antarctica", phoneCode: 0 },
    { id: 98, code: "HK", name: "Hong Kong S.A.R.", continent: "Asia", phoneCode: 852 },
    { id: 99, code: "HU", name: "Hungary", continent: "Europe", phoneCode: 36 },
];

const lookInCountryData = (key) => (code) => {
    const obj = countryData.find((i) => i.code === code);
    return obj && obj[key] ? obj[key] : "";
};

export const getPhoneCode = lookInCountryData("phoneCode");
export const getCountryName = lookInCountryData("name");
