import React, { Component } from "react";
import { connect } from "react-redux";
import PackageItem from "./PackageItem";

class Packages extends Component {
    packageStyling = () => {
        const { packages } = this.props;
        const count = [packages.p1IsEnable, packages.p2IsEnable, packages.p3IsEnable].filter(Boolean).length;
        return count ? "col-md-" + 12 / count : "";
    };

    itemObj = (pack) => {
        const { t, itemData, packages, adminPackage } = this.props;
        if (!packages[`p${pack}IsEnable`]) return null;

        switch (pack) {
            case 3:
                return {
                    desc: packages.p3Title === "Bespoke" ? adminPackage?.bespoke : adminPackage?.custom,
                    title: packages.p3Title,
                    custom: true,
                };

            case 2:
            case 1:
                const obj = {
                    title: packages[`p${pack}Title`],
                    desc: packages[`p${pack}Description`],
                };

                [1, 2, 3].forEach((num) => {
                    const key = `bundle` + num;

                    if (!packages[`p${pack}Ofr${num}`]) {
                        obj[key] = null;
                    } else {
                        obj[key] = {
                            info: packages[`p${pack}Offer${num}`],
                            price: packages[`p${pack}Offer${num}Price`],
                            bundle: "Bundle " + num,
                            title: obj.title,
                            slug: `${itemData.storeName}-${obj.title}-${key}`.replace(/\s/, "").toLowerCase(),
                            market: itemData?.targetMarket,
                            store: itemData?.storeName,
                            storeEmail: itemData?.email,
                            section: "package",
                        };
                    }
                });
                return obj;

            default:
        }

        return null;
    };

    render() {
        const { t, itemData, packages, adminPackage } = this.props;
        const pack1 = this.itemObj(1);
        const pack2 = this.itemObj(2);
        const pack3 = this.itemObj(3);

        console.log(pack1, pack2, pack3);

        return (
            <div className="tab-pane" id="home" role="tabpanel" aria-labelledby="home-tab">
                <div className="form-row">
                    {pack1 ? <PackageItem {...pack1} t={t} className={this.packageStyling()} itemData={itemData} /> : null}
                    {pack2 ? <PackageItem {...pack2} t={t} className={this.packageStyling()} itemData={itemData} /> : null}
                    {pack3 ? <PackageItem {...pack3} t={t} className={this.packageStyling()} itemData={itemData} /> : null}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    adminPackage: state.admin.admin.packages,
});

export default connect(mapStateToProps, null)(Packages);
