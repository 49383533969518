import React from "react";
import EditButton from "../../../ui/Buttons/EditButton";
import SaveButton from "../../../ui/Buttons/SaveButton";
import CancelButton from "../../../ui/Buttons/CancelButton";
import isEmpty from "../../../../utils/is-empty";
import SelectListGroup from "../../../common/SelectListGroup";

import {getCountryName} from "../../../../utils/getCountryName";

const BlockCountriesView = ({ data, onClickEdit, onClickSave, getCountries, continentOptions, countryOptions, onBlock, onUnBlock, countries, onChange }) => {
    return (
        <div className="tab-pane" id="services" role="tabpanel">
            <div className="row">
                <div className="col text-right">
                    <EditButton onClick={onClickEdit} disabled={data.editdisabled} type="Edit">
                        Edit
                    </EditButton>
                </div>
                <div className="col-md-12 text-center">
                    <h4 className="font-32 tabs-title mt-4">Block Countries</h4>
                </div>
                <form className="w-100" onSubmit={onBlock} noValidate>
                    <div className="container">
                        <div className="mb-4 d-flex align-items-center justify-content-center">
                            <div className="form-group mr-2 mb-0">
                                <SelectListGroup name="continent" value={data.continent} onChange={getCountries} options={continentOptions} disabled={data.disabled} />
                            </div>
                            {data.input ? (
                                <div className="form-group mr-2 mb-0">
                                    <SelectListGroup name="country" value={data.country} onChange={onChange} options={countryOptions} disabled={data.disabled} />
                                </div>
                            ) : null}
                            {data.blockButton ? (
                                <div className="form-group mr-2 mb-0">
                                    <button className="btn btn-success" type="submit" onClick={onBlock} disabled={data.disabled}>
                                        Block
                                    </button>
                                </div>
                            ) : null}
                        </div>
                    </div>
                </form>
            </div>
            <div className="row mt-20">
                <div className="col-md-6 offset-md-3 text-center">
                    {isEmpty(countries) ? (
                        <div className="row">
                            <div className="col-md-8 offset-md-2">
                                <div className="error-container">
                                    <h5 className="error-title">Currently there is no country blocked</h5>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <table class="table tableborder">
                            <thead class="thead-dark">
                                <tr>
                                    <th>Conuntry Name</th>

                                    <th>Block/Unblock</th>
                                </tr>
                            </thead>
                            <tbody>
                                {countries.map((country) => (
                                    <tr key={country._id}>
                                        <td>{getCountryName(country.sortname)}</td>

                                        <td>
                                            <SaveButton onClick={() => onUnBlock(country.sortname)} type="save" label="UnBlock" disabled={data.disabled} />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}
                </div>
            </div>
            <div className="bd-top" />
            <div className="osr-btn-group p-b-15  text-center">
                <SaveButton onClick={onClickSave} label="Save" type="save" disabled={data.disabled} />
                <CancelButton onClick={onClickEdit} label="Cancel" type="cancel" disabled={data.disabled} />
            </div>
        </div>
    );
};

export default BlockCountriesView;
