export const labels = {
    eng: {
        aboutUs: "About us",
        addBranch: "Add Branch Office",
        address: "Address",
        adultOnly: "Adult only",
        alphanumeric: "Alphanumeric Password (min 8 characters)",
        apt: "Apt",
        attach: "attach",
        attachFrom: "Attach from",
        awardPrograms: "Award programs",
        bespoke: "Bespoke",
        branchOffice: "Branch Office",
        bridal: "Bridal",
        bronze: "Bronze",
        buisinessTitle: "Business Title",
        business: "Business",
        businessWebsite: "Business or Personal website",
        canCater: "Can cater for up to",
        cancel: "Cancel",
        categories: "Categories",
        category: "Category",
        cateringCapacity: "Catering capacity",
        ceo: "CEO",
        charity: "Charity",
        city: "City",
        clark: "Clark",
        clearFilters: "Clear filter",
        commemorative: "Commemorative",
        comments: "COMMENTS",
        contactAdmin: "Contact Admin",
        continent: "Continent",
        continue: "Continue",
        country: "Country",
        custom: "Custom",
        dashboard: "Dashboard",
        description: "Description",
        diamond: "Diamond",
        director: "Director",
        dropbox: "Dropbox",
        edit: "Edit",
        educational: "Educational",
        emailAddress: "Email Address",
        enable: "Enable",
        entertainmentIndustry: "Entertainment industry",
        eventPlanner: "Event Planner",
        eventType: "Event types",
        eventVendor: "Event Vendor",
        facebookAddress: "Facebook address",
        filterBy: "Filter by",
        fireworks: "Fireworks",
        firstName: "First Name",
        forgetPassword: "FORGOT PASSWORD",
        fundraiser: "Fundraiser",
        generalManager: "General Manager",
        gold: "Gold",
        googleDrive: "Google drive",
        guest: "guest",
        home: "Home",
        iAm: "I Am An",
        instagramHandle: "Instagram handle",
        keywords: "Keywords",
        lastName: "Last Name",
        launch: "Launch",
        linkedInaddress: "LinkedIn address",
        login: "Login",
        logout: "Logout",
        mainOffice: "Main office",
        mainStream: "Mainstream",
        mainWelcome: "WELCOME",
        manager: "Manager",
        managerDirector: "Managing Director",
        marketMix: "MarketMix",
        memberSince: "Member since: ",
        mobileNo: "Mobile no",
        NewPassword: "New password",
        noticeBoard: "Notice Board",
        office: "Office",
        offices: "OFFICES",
        officialPosition: "Official Position",
        oldPassword: "Old password",
        operatingSince: "Business operating since: ",
        optional: "Optional",
        packages: "Packages",
        packagesLarge: "PACKAGES",
        password: "Password",
        personalInfo: "Personal Info",
        phnNoVisibility: "Make phone numbers visible",
        phoneNumber: "Phone number",
        planner: "Planner",
        planners: "Planners",
        platinum: "Platinum",
        potalCode: "Potal code",
        price: "Price",
        privacyPolicy: "Privacy Policy",
        profile: "Profile",
        reEnter: "Re Enter",
        register: "Register",
        registerNow: "Register Now",
        removeBranch: "Remove Branch Office",
        resetPassword: "Reset Password",
        reviews: "Reviews",
        save: "Save",
        searchHere: "SEARCH HERE",
        searchText: "All searchable Event planners in this directory are based or registered in Africa countries",
        serarchText: "All searchable Event planners in this directory are based or registered in Africa countries",
        services: "Services",
        servicesLarge: "SERVICES",
        setting: "Setting",
        silver: "Silver",
        smallParties: "Small Parties",
        sponsoredBy: "Sponsored by",
        sport: "Sport",
        staff: "Staff",
        state: "State",
        store: "Store",
        storeName: "Store Name",
        streetAddress: "Street address",
        submit: "Submit",
        summury: "Summury",
        supervisor: "Supervisor",
        servicesPrice: "$200",
        targetMarket: "Target Market",
        telephone: "Telephone",
        tellUs: "Tell us a little about yourself",
        termsCondition: "Terms & Conditions",
        themedParties: "Themed Parties",
        title: "Title",
        titleorService: "Title or services offered",
        titleSummary: "Enter summary / descrption / variations of what is on offer here",
        twitterHandle: "Twitter handle",
        uploadFile: "Upload file",
        uploadImage: "Upload Images",
        uploadImage2: "upload image",
        uploadImageWarnings: "Only JPG's and PNG's are allowed! Use 1040 * 800 for best results.",
        upMarket: "UpMarket",
        vendor: "Vendor",
        vendors: "Vendors",
        viewDetails: "View Details",
        warning: "Warning",
        website: "Website",
        weddingPlanners: "Wedding Planners",
        weddingProposals: "Wedding proposals",
        welcome: "Welcome",
        welcomeLine: "to The Planner's Directory",
        wIsCurrent: "What is your current revenue?",
        yourComputer: "Your computer",
        youTubeAddress: "YouTube address",
        zipCode: "Zip / Potal code",
    },
    por: {
        aboutUs: "Sobre nós",
        addBranch: "Adicionar Escritório Filial",
        address: "Endereço",
        adultOnly: "Somente Adultos",
        alphanumeric: "Senha Alfanumérica (mín. 8 caracteres)",
        apt: "Apartamento",
        attach: "Anexar",
        attachFrom: "Anexar de",
        awardPrograms: "Programas com prêmios",
        bespoke: "Sob medida",
        branchOffice: "Filial",
        bridal: "Noivado",
        bronze: "Bronze",
        buisinessTitle: "Título do Negócio",
        business: "Website pessoal ou do seu negócio",
        businessWebsite: "Website pessoal ou do seu negócio",
        canCater: "Pode atender até",
        cancel: "Cancelar",
        categories: "Categorias",
        category: "Categoria",
        cateringCapacity: "Capacidade de restauração",
        ceo: "CEO",
        charity: "Caridade",
        city: "Cidade",
        clark: "Encarregado",
        clearFilters: "Limpar filtros",
        commemorative: "Comemorativo",
        comments: "COMENTÁRIOS",
        contactAdmin: "Contate-Nos",
        continent: "Continente",
        continue: "Continuar",
        country: "País",
        custom: "Customizado",
        dashboard: "Painel",
        description: "Descrição",
        diamond: "Diamante",
        director: "Diretor",
        dropbox: "Dropbox",
        edit: "Editar",
        educational: "Educacional",
        emailAddress: "Endereço de e-mail",
        enable: "Habilitar",
        entertainmentIndustry: "Indústria do entretenimento",
        eventPlanner: "Planejador de Eventos",
        eventType: "Tipos de eventos",
        eventVendor: "Vendedor de Eventos",
        facebookAddress: "Endereço do Facebook",
        filterBy: "Fitrar por",
        fireworks: "Fogos de Artificios",
        firstName: "Primeiro Nome",
        forgetPassword: "ESQUECI MINHA SENHA",
        fundraiser: "Arrecadação ",
        generalManager: "Gerente Geral",
        gold: "Ouro",
        googleDrive: "Google drive",
        guest: "convidados",
        home: "Página inicial",
        iAm: "Eu sou um",
        instagramHandle: "Instagram",
        keywords: "Palavras chave",
        lastName: "Último Nome",
        launch: "Lançamento ",
        linkedInaddress: "Endereço do LinkedIn",
        login: "Login",
        logout: "Logout",
        mainOffice: "Sede",
        mainStream: "Fluxo principal",
        mainWelcome: "BOAS-VINDAS",
        manager: " Gerente",
        managerDirector: "Diretor Geral",
        marketMix: "Mistura de Mercado",
        mobileNo: "Número do telefone",
        NewPassword: "Senha nova",
        noticeBorad: "Quadro de Avisos",
        office: "Escritório",
        offices: "ESCRITÓRIOS",
        officialPosition: "Posição Oficial",
        oldPassword: "Senha antiga",
        optional: "Opcional",
        packages: "Pacotes ",
        packagesLarge: "PACOTES",
        password: "Senha ",
        personalInfo: "Informações Pessoais",
        phoneNumber: "Número de telefone",
        planner: "Planejador",
        planners: "Planejadores",
        platinum: "Platina ",
        potalCode: "CEP",
        price: "Preço",
        privacyPolicy: "Política de Privacidade",
        profile: "Perfil",
        reEnter: "Insira o seu",
        register: "Registrar",
        registerNow: "Registrar-se agora",
        removeBranch: "Remover Escritório Filial",
        reviews: "Rever",
        save: "Salvar",
        searchHere: "PESQUISAR AQUI",
        searchText: "Todos os planejadores de Eventos disponíveis neste diretório estão registrados em países da África",
        serarchText: "Todos os planejadores de Eventos disponíveis neste diretório estão registrados em países da África",
        services: "Serviços",
        servicesLarge: "SERVIÇOS",
        setting: "Configurações",
        silver: "Prata",
        smallParties: "Pequenas Festas (até 50 convidados)",
        sponsoredBy: "Patrocinado por",
        sport: "Esportes",
        staff: "Equipe",
        state: "Estado",
        store: "Loja",
        storeName: "Nome da Loja",
        streetAddress: "Endereço",
        summury: "Resumo",
        supervisor: "Supervisor",
        targetMarket: "Mercado Alvo",
        telephone: "Telefone",
        tellUs: "Conte-nos um pouco sobre você mesmo",
        termsCondition: "Termos e Condições",
        themedParties: "Festas Temáticas",
        title: "Título",
        titleorService: "Título ou serviços oferecidos",
        titleSummary: "Inserir resumo / descrição / variações do que está disponível aqui",
        twitterHandle: "Twitter",
        uploadFile: "Fazer o upload do arquivo",
        uploadImage: "Fazer upload das imagens",
        uploadImage2: "fazer o upload da imagem",
        uploadImageWarnings: "Somente JPG e PNG são permitidos!",
        upMarket: "UpMarket",
        vendor: "Vendedor",
        vendors: " Vendedores",
        viewDetails: "Visualizar detalhes",
        warning: "Aviso ",
        website: "Website",
        weddingPlanners: "Organizadores de Casamentos",
        weddingProposals: "Propostas de casamento",
        welcome: "Boas-vindas",
        welcomeLine: "para O Diretório do Organizador",
        wIsCurrent: "Qual é a sua receita?",
        yourComputer: "Seu computador",
        youTubeAddress: "Endereço do YouTube",
        zip: "CEP",
        zipCode: "CEP",
    },
    swa: {
        aboutUs: "Kuhusu sisi",
        addBranch: "Ongeza Ofisi",
        address: "Anwani",
        adultOnly: "Watu wazima tu",
        alphanumeric: "Neno la siri lenye herufi na namba (kuanzia herufi 8)",
        apt: "Nyumba",
        attach: "Ambatanisha",
        attachFrom: "Ambatanisha kutoka",
        awardPrograms: "Programu za tuzo",
        bespoke: "Maalumu kwa mteja",
        branchOffice: "Ofisi ya tawi",
        bridal: "Bi Harusi",
        bronze: "Shaba",
        buisinessTitle: "Jina la Biashara",
        business: "Tovuti ",
        businessWebsite: "Tovuti ya Biashara au Binafsi",
        canCater: "Inawezekana kuwahudumia mpaka",
        cancel: "Batilisha",
        categories: "Kategoria",
        category: "Kategoria",
        cateringCapacity: "Uwezo wa kuhudumia",
        ceo: "CEO",
        charity: "Kujitolea",
        city: "Mji",
        clark: "Muuza duka",
        clearFilters: "Futa vichungi",
        commemorative: "Ukumbusho",
        comments: "MAONI",
        contactAdmin: "Wasiliana nasi",
        continent: "Bara",
        continue: "Endelea",
        country: "Nchi",
        custom: "Tengeneza unavyopendelea",
        dashboard: "Dashibodi",
        description: "Maelezo",
        diamond: "Almasi",
        director: "Mkurugenzi",
        dropbox: "Dropbox",
        edit: "Rekebisha",
        educational: "Ya Elimu",
        emailAddress: "Barua Pepe",
        enable: "Wezesha",
        entertainmentIndustry: "Sekta ya burudani",
        eventPlanner: "Mpangiliaji wa Matukio",
        eventType: "Aina za matukio",
        eventVendor: "Muuza bidhaa katika Matukio",
        facebookAddress: "Anuani ya Facebook",
        filterBy: "Chuja kwa",
        fireworks: "Fataki",
        firstName: "Jina La Kwanza",
        forgetPassword: "UMESAHAU NENO LA SIRI",
        fundraiser: "Kuchangisha fedha",
        generalManager: "Meneja Mkuu",
        gold: "Dhahabu",
        googleDrive: "Google drive",
        guest: "wageni",
        home: "Nyumbani",
        iAm: "Mimi ni An",
        instagramHandle: "Jina la Instagram",
        keywords: "Maneno ya msingi",
        lastName: "Jina La Mwisho",
        launch: "Uzinduzi",
        linkedInaddress: "Anuani ya LinkedIn",
        login: "Ingia",
        logout: "Toka ",
        mainOffice: "Ofisi kuu",
        mainStream: "Maarufu",
        mainWelcome: "KARIBU",
        manager: "Meneja",
        managerDirector: "Mkurugenzi Mtendaji",
        marketMix: "Mchanganyiko wa Soko",
        mobileNo: "Namba ya simu ya mkononi",
        NewPassword: "Neno la siri jipya",
        noticeBoard: "Ubao wa Matangazo",
        office: "Ofisi",
        offices: "OFISI",
        officialPosition: "Cheo Rasmi",
        oldPassword: "Neno la siri la zamani",
        optional: "Ya hiari",
        packages: "Vifurushi",
        packagesLarge: "VIFURUSHI",
        password: "Neno la siri",
        personalInfo: "Taarifa Binafsi",
        phoneNumber: "Namba ya simu ya mkononi",
        planner: "Wapangiliaji",
        planners: "Wapangiliaji",
        platinum: "Platini",
        potalCode: "Zip/ Potal code",
        price: "Bei",
        privacyPolicy: "Sera ya faragha",
        profile: "PMaelezo yangu Mafupi",
        reEnter: "Ingiza Yako",
        register: "Jisajili",
        registerNow: "Jisajili Sasa",
        removeBranch: "Ondoa Ofisi ",
        reviews: "Maoni",
        save: "Hifadhi",
        searchHere: "TAFUTA HAPA",
        searchText: "Wapangiliaji wa Matukio wote unaoweza kuwatafuta katika orodha hii wanatokea au wamesajili kutokea nchi za Africa",
        services: "Huduma",
        servicesLarge: "HUDUMA",
        setting: "Mipangilio",
        silver: "Fedha",
        smallParties: "Sherehe ndogo (mpaka wageni 50)",
        sponsoredBy: "Imedhaminiwa na",
        sport: "Mchezo",
        staff: "Wafanyakazi",
        state: "Jimbo",
        store: "Duka",
        storeName: "Jina la Duka",
        streetAddress: "Anuani ya mtaa",
        summury: "Muhtasari",
        supervisor: "Msimamizi",
        targetMarket: "Soko Lengwa",
        telephone: "Simu ya mezani",
        tellUs: "Tuambie kidogo kuhusu wewe",
        termsCondition: "Vigezo & Masharti",
        themedParties: "Sherehe za Mandhari",
        title: "Cheo",
        titleorService: "Jina au huduma inayotolewa",
        titleSummary: "Ingiza muhtasari / maelezo mafupi / aina tofauti za vitu vilivyo kwenye ofa hapa",
        twitterHandle: "Jina la Twitter",
        uploadFile: "Pakia faili",
        uploadImage: "Pakia Picha",
        uploadImage2: "pakia picha",
        uploadImageWarnings: "Ni JPG tu na PNG zinazoruhusiwa!",
        upMarket: "Masoko Ya Juu",
        vendor: "Wauzaji",
        vendors: "Wauzaji",
        viewDetails: "Tazama Taarifa",
        warning: "Onyo",
        website: "Tovuti",
        weddingPlanners: "Wapambaji wa Harudi",
        weddingProposals: "Pendekezo la harusi",
        welcome: "Karibu",
        welcomeLine: "kwenye Planner's Directory",
        wIsCurrent: "Je, ni nini mapato yako?",
        yourComputer: "Kwenye kompyuta yako",
        youTubeAddress: "Anuani ya YouTube",
        zipCode: "Zip/ Potal code",
    },
    fre: {
        aboutUs: "À propos de nous",
        addBranch: "Ajouter une succursale",
        address: "Adresse",
        adultOnly: "Réservé aux adultes",
        alphanumeric: "Mot de passe alphanumérique (min. 8 caractères)",
        apt: "Appart, suite",
        attach: "Joindre",
        attachFrom: "Joindre à partir de",
        awardPrograms: "Programmes de récompense",
        bespoke: "Sur mesure",
        branchOffice: "Succursale",
        bridal: "Nuptial",
        bronze: "Bronze",
        buisinessTitle: "Fonction",
        business: "Site internet professionnel ou personnel",
        businessWebsite: "Site internet professionnel ou personnel",
        canCater: "Peut accueillir jusqu'à",
        cancel: "Annuler",
        categories: "Catégories",
        category: "Catégorie",
        cateringCapacity: "Capacité de restauration",
        ceo: "PDG",
        charity: "Œuvre de bienfaisance",
        city: "Ville",
        clark: "Employé",
        clearFilters: "Effacer les filtres",
        commemorative: "Commératif",
        comments: "COMMENTAIRES",
        contactAdmin: "Contacter l'administrateur",
        continent: "Continent",
        continue: "Continuer",
        country: "Pays",
        custom: "Personnaliser",
        dashboard: " Tableau de bord ",
        description: "Description",
        diamond: "Diamant",
        director: "Directeur",
        dropbox: "Boîte de dépôt",
        edit: "Modifier",
        educational: "Educatif",
        emailAddress: "Adresse électronique",
        enable: "Activer",
        entertainmentIndustry: "Industrie du divertissement",
        eventPlanner: "Planificateur d'événements",
        eventType: "Type d'évenements",
        eventVendor: "Promoteur d'événements",
        facebookAddress: "Adresse facebook",
        filterBy: "Filter par",
        fireworks: "Feux d'artifice",
        firstName: "Prénom",
        forgetPassword: "MOT DE PASSE OUBLIÉ",
        fundraiser: "Levée de fonds",
        generalManager: "Directeur général",
        gold: "Or ",
        googleDrive: "Moteur de recherche Google",
        guest: "invités",
        home: "Accueil",
        iAm: "Je suis un",
        instagramHandle: "Poignée Instagram",
        keywords: "Mots clés",
        lastName: "Nom de famille",
        launch: "Lancement",
        linkedInaddress: "Adresse LinkedIn",
        login: "S'Identifier",
        logout: "se déconnecter",
        mainOffice: "Bureau principal",
        mainWelcome: "BIENVENUE",
        manager: "Directeur",
        managerDirector: "Directeur général",
        marketMix: "Mix de marché",
        mobileNo: "Numéro de portable",
        nainStream: "Marché moyen",
        NewPassword: "Nouveau mot de passe",
        noticeBoard: "Tableau d'affichage",
        office: "Bureau",
        offices: "BUREAUX",
        officialPosition: "Poste",
        oldPassword: "Ancien mot de passe",
        optional: "Facultatif",
        packages: "Paquets ",
        packagesLarge: "FORFAITS",
        password: "Mot de passe",
        personalInfo: "Informations personnelles ",
        phoneNumber: "Numéro de téléphone",
        planner: "Plannificateur",
        planners: "Plannificateurs",
        platinum: "Platine",
        potalCode: "Adresse / Boite postale",
        price: "Prix",
        privacyPolicy: "Politique de confidentialité",
        profile: "Profil ",
        reEnter: "Entrez votre",
        register: "Répertoire",
        registerNow: "S'inscire maintenant",
        removeBranch: "Supprimer une succursale",
        reviews: "Commentaires",
        save: "Sauvegarder",
        searchHere: "Chercher ici",
        searchText: "Tous les planificateurs d’événements consultables de ce répertoire sont basés ou enregistrés dans des pays d’Afrique",
        services: "Prestations de service",
        servicesLarge: "PRESTATIONS",
        setting: "Paramètres",
        silver: "Argent",
        smallParties: "Petites fetes (jusqu'à 50 invités)",
        sponsoredBy: "Sponsorisé par",
        sport: "Sport",
        staff: "Personnel",
        state: "Etat",
        store: "Magasin / Boutique",
        storeName: "Nom du magasin",
        streetAddress: "Nom de rue",
        summury: "Liste récapitulative",
        supervisor: "Superviseur",
        targetMarket: "Marché cible",
        telephone: "Téléphone",
        tellUs: "Parlez-nous un peu de vous",
        termsCondition: "Termes & Conditions",
        themedParties: "Soirées à thème",
        title: "Titre",
        titleorService: "Intitulé ou prestations proposés",
        titleSummary: "Entrez résumé / description / types de prestations proposées ici",
        twitterHandle: "Poignée Twitter",
        uploadFile: "Importer le fichier",
        uploadImage: "Importer des images",
        uploadImage2: "Importer l'image",
        uploadImageWarnings: "Seuls les fichiers JPG et PNG sont autorisés!",
        upMarket: "Haut de gamme",
        vendor: "Vendeur",
        vendors: "Vendeurs",
        viewDetails: "Voir les détails",
        warning: "Attention",
        website: "Site internet",
        weddingPlanners: "Planificateurs de mariage",
        weddingProposals: "Propositions de mariage",
        welcome: "Bienvenue",
        welcomeLine: "dans l'annuaire du planificateur",
        wIsCurrent: "Quel est votre revenu actuel?",
        yourComputer: "Votre ordinateur",
        youTubeAddress: "Adresse YouTube ",
        zipCode: "Adresse / Boite postale",
    },
};
