import React, { useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { getCompareData, setCompareData } from "../../../actions/compareActions";

const PackageItem = ({ t, title, desc, custom, className, compareData, itemData, ...props }) => {
    console.log(props);
    const handleEnvelope = useCallback((pTitle, bundle) => {
        window.location.href = `mailto:${itemData.email}?subject=Inquiry on ${bundle ? bundle : "Bundle"} of ${pTitle} package`;
    });

    const addToCompare = useCallback((item, action = "add") => {
        if (action === "add") {
            if (compareData.error) alert(compareData.error);
            if (compareData?.items && Object.keys(compareData?.items).length && compareData?.market !== item?.market) {
                const goNext = window.confirm("You can only compare PACKAGES from same MARKET TYPE.\nWould you like to change the MARKET TYPE?");
                if (!goNext) return;
            }
            localStorage.setItem("scrollTop", true);
        }
        props.setCompareData(action, item);
    });

    const renderListItem = (item, index) => (
        <li key={index} className="list-group-item">
            <h3 className="title">{item.bundle}</h3>
            <span className="h6 text-muted">{item.price}</span>
            <p className="my-2">{item.info}</p>

            <div style={{ textAlign: "center" }}>
                <button type="button" onClick={() => handleEnvelope(item.title, item.bundle)} className="btn btn-sm btn-warning mr-3">
                    <img src="https://s2.svgbox.net/materialui.svg?ic=email&amp;color=FFFFFF" alt="email" width="20" height="20" />
                </button>

                {!Object.keys(compareData?.items).includes(item?.slug) ? (
                    <button type="button" onClick={() => addToCompare(item)} className="btn btn-sm btn-primary">
                        Add to compare
                    </button>
                ) : (
                    <button type="button" onClick={() => addToCompare(item, "remove")} className="btn btn-sm btn-danger">
                        Remove from Compare
                    </button>
                )}
            </div>
        </li>
    );

    return (
        <div className={className}>
            <div className={`price-table radius-5 bg-image-${title}`}>
                <div className="min-h">
                    <div className={`price-table-head ${title}`}>
                        <h6>{title}</h6>
                    </div>
                    {desc ? <div className="price-table-caption">{desc}</div> : null}
                    {custom ? (
                        <div style={{ textAlign: "center" }}>
                            <button type="button" onClick={() => handleEnvelope(title)} className="btn btn-warning mr-3 align-center">
                                <img src="https://s2.svgbox.net/materialui.svg?ic=email&amp;color=FFFFFF" alt="email" />
                            </button>
                        </div>
                    ) : null}
                </div>
                <ul className={`list-group list-group-flush bg-image-${title}`}>{[props.bundle1, props.bundle2, props.bundle3].filter(Boolean).map(renderListItem)}</ul>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    compareData: state.compare,
});
export default connect(mapStateToProps, { getCompareData, setCompareData })(PackageItem);
