import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";

const HtmlAlert = (props) => {
    const [html, setHtml] = useState(props.html || "");

    useEffect(() => {
        try {
            setHtml(Buffer.from(props.match.params.hexo, "hex")?.toString("utf8"));
        } catch (err) {
            if (!html) props.history.push("/error404");
        }
    });

    return (
        <section
            className="d-flex flex-column align-items-center  justify-content-center h-100"
            style={{
                opacity: 0.9,
                backgroundImage: `url(https://picsum.photos/1080/720)`,
                backgroundPosition: "center",
            }}
        >
            <div className="card shadow">
                <div className="card-body" dangerouslySetInnerHTML={{ __html: html }}></div>
            </div>
        </section>
    );
};

export default withRouter(HtmlAlert);
