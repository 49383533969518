import React from "react";

const ServiceItem = ({ tname, tvalue, dname, dvalue, pname, pvalue, onChange, counterLength, bg, t }) => {
    return (
        <div className="col-md-4 ">
            <div className="card m-b-30 border-1-gray">
                <div className={`card-body ${bg}`}>
                    <h6 className="text-muted mt-3">
                        <b>{t.title}</b>
                    </h6>
                    <input name={tname} value={tvalue} onChange={onChange} type="text" className="form-control" placeholder={t.titleorService} />
                    <h6 className="text-muted mt-3">
                        <b>{t.description}</b>
                        <div className="float-right">
                            <span className="chars">{counterLength}</span>/300
                        </div>
                    </h6>
                    <textarea name={dname} value={dvalue} onChange={onChange} id="textarea" className="form-control" maxLength={300} rows={3} placeholder={t.titleSummary} />
                    <h6 className="text-muted mt-3">
                        <b>{t.price}</b>
                    </h6>
                    <input name={pname} value={pvalue} onChange={onChange} type="text" className="form-control" placeholder={t.servicesPrice} />
                </div>
            </div>
            {/* end card */}
        </div>
    );
};

export default ServiceItem;
