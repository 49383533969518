import React, { useState } from "react";
import { Link } from "react-router-dom";
import ImageGallery from "react-image-gallery";
import Modal from "react-modal";
import Worldwide from "../icons/worldwide.svg";
import Facebook from "../icons/facebook.svg";
import LinkedIn from "../icons/linkedin.svg";
import Instagram from "../icons/instagram.svg";
import PlannerPlaceholder from "../../../../img/images/default-planner.jpg";
import VendorPlaceHolder from "../../../../img/images/default-vendor.jpg";
import moment from "moment";

import isEmpty from "../../../../utils/is-empty";

const PlannerItem = ({ user, pathname, t, imageValues }) => {
    const { slug, categories, eventTypes, description, targetMarket, phnIsVisible, officeMobileNo, officeTele1, officeTele2, instaAdress, website, storeName, twitterAdress, linkdnAdress, fbAdress, images } = user;

    const customStyles = {
        content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
        },
    };

    //React Hooks
    // const [modalIsOpen, setPhotoIndex] = useState(0);
    const [isOpen, setIsOpen] = useState(false);
    const [items, setItems] = useState([]);

    // const imageValues = !isEmpty(images) ? Object.values(images) : "";
    const renderModalImages = (imageValues) => {
        let items = [];
        for (let i = 0; i < imageValues.length; i++) {
            items.push({
                original: imageValues[i],
                originalClass: "react-img-gallery",
            });
        }
        setItems(items);
        setIsOpen(true);
    };

    const targetMarketStyling = (tmarket) => {
        if (tmarket === "up") return t.upMarket;
        if (tmarket === "mix") return t.marketMix;
        if (tmarket === "stream") return t.mainStream;
    };

    return (
        <div className="package-thumb-wrap list-style">
            <div className="package-thumb-header pb-3">
                <div className="row">
                    <div className="col-md">
                        <h6>
                            <strong>{storeName}</strong>
                        </h6>
                        <br />
                        <div className="header_store_since">
                            <label>{t.memberSince + " " + moment(user.date).format("YYYY")}</label>
                        </div>
                        <div className="header_store_since">
                            <label>{t.operatingSince + " " + moment(user.operatingSince || user.date).format("YYYY")}</label>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="post-meta mb-0">
                            <h5>{t.categories}:</h5>
                            <ul>
                                {categories.map((category) => (
                                    <li key={category._id}>{category.label}</li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    {
                        /* pathname === "/planners" && */ !isEmpty(eventTypes) ? (
                            <div className="col-md-5">
                                <div className="post-meta mb-0">
                                    <h5>{t.eventType + ":"}</h5>
                                    <ul>
                                        {eventTypes.map((event) => (
                                            <li>{event.label}</li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        ) : null
                    }
                </div>
            </div>
            <div className="row mt-3">
                <div className="col-md-6">
                    <div className="package-thumb-caption border-0">
                        <p style={{ overflowWrap: "anywhere" }}>{description}</p>
                    </div>
                </div>
                {!isEmpty(imageValues) ? (
                    <div className="col-md-6">
                        <div className="package-img---thumb ratio16x9" onClick={() => renderModalImages(imageValues)}>
                            <img src={imageValues[0]} alt="First" />
                        </div>
                    </div>
                ) : (
                    <div className="col-md-6">
                        <div className="package-img---thumb ratio16x9">
                            <img src={pathname === "/planners" ? PlannerPlaceholder : VendorPlaceHolder} alt="planner-directory-placeholder" />
                        </div>
                    </div>
                )}
            </div>
            <div className="package-thumb-footer border-0 pt-3">
                <div className="d-flex justify-content-between pb-2">
                    <div className="d-flex flex-wrap">
                        {(website || fbAdress || linkdnAdress || instaAdress) && (
                            <div className="text-left pr-5">
                                <ul className="contact-list social-list">
                                    {website ? (
                                        <li>
                                            <a href={website} target="_blank" rel="noopener noreferrer">
                                                <img src={Worldwide} alt="Website Adress" />
                                            </a>
                                        </li>
                                    ) : null}
                                    {fbAdress ? (
                                        <li>
                                            <a href={fbAdress} target="_blank" rel="noopener noreferrer">
                                                <img className="" src={Facebook} alt="Facebook Addres" />
                                            </a>
                                        </li>
                                    ) : null}
                                    {linkdnAdress ? (
                                        <li>
                                            <a href={linkdnAdress} target="_blank" rel="noopener noreferrer">
                                                <img src={LinkedIn} alt="LinkedIn Adress" />
                                            </a>
                                        </li>
                                    ) : null}

                                    {instaAdress ? (
                                        <li>
                                            <a href={instaAdress} target="_blank" rel="noopener noreferrer">
                                                <img src={Instagram} alt="Instagram Adress" />
                                            </a>
                                        </li>
                                    ) : null}
                                </ul>
                            </div>
                        )}
                        <div className="row">
                            {phnIsVisible &&
                                [officeMobileNo, officeTele1, officeTele2].filter(Boolean).map((phn, idx) => (
                                    <div key={idx} className="d-flex align-items-center mx-2">
                                        <i class="fa fa-phone" />
                                        &nbsp; {phn}
                                    </div>
                                ))}
                        </div>
                    </div>
                    <div className="small-btn-wrap d-flex justify-content-end align-items-center">
                        <span className={targetMarket}>{targetMarketStyling(targetMarket)}</span>
                        {slug && (pathname === "/planners" || pathname === "/vendors") ? (
                            <Link className={`btn th-bg badge p-2`} to={{ pathname: `${pathname}/${slug}`, state: { userId: user.user } }}>
                                {t.viewDetails}
                            </Link>
                        ) : null}
                    </div>
                </div>
            </div>

            <Modal isOpen={isOpen} onRequestClose={() => setIsOpen(false)} style={customStyles} overlayClassName="react-modal-overlay">
                <div className="modal-header" style={{ padding: "5px", background: "white" }}>
                    <button type="button" className="close" onClick={() => setIsOpen(false)}>
                        <span aria-hidden="true">&times;</span>
                        <span className="sr-only">Close</span>
                    </button>
                </div>
                <ImageGallery items={items} showBullets={true} showIndex={true} showThumbnails={false} showPlayButton={false} showFullscreenButton={false} autoPlay={true} showNav={false} slideDuration={100} onImageError={pathname === "/planners" ? PlannerPlaceholder : VendorPlaceHolder} />
            </Modal>
        </div>
    );
};

export default PlannerItem;
