import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

const InputGroup = ({ name, placeholder, value, error, icon, type, onChange, pattern }) => {
    return (
        <React.Fragment>
            {/* <div className="input-group-prepend">
        <span className="input-group-text">
          <i className={icon} />
        </span>
      </div> */}
            <input
                className={classnames("form-control ", {
                    "is-invalid": error,
                })}
                type={type || "text"}
                placeholder={placeholder}
                name={name}
                value={value}
                onChange={onChange}
                pattern={pattern}
            />
            {error && <div className="invalid-feedback">{error}</div>}
        </React.Fragment>
    );
};

InputGroup.propTypes = {
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    value: PropTypes.string.isRequired,
    icon: PropTypes.string,
    error: PropTypes.string,
    type: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

InputGroup.defaultProps = {
    type: "text",
};

export default InputGroup;
