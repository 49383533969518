import React, { Component } from "react";
import { forgotUser } from "../../../actions/authActions";
import { connect } from "react-redux";
import classnames from "classnames";
import { Link, withRouter } from "react-router-dom";
import { resetResults, clearAlerts } from "../../../actions/common";
import Joi from "joi";
import Form from "../../common/form";
import isEmpty from "../../../utils/is-empty";
import Loader from "react-loader-spinner";

import Background from "../bg.jpg";

var BackgroundStyle = {
    backgroundImage: "url(" + Background + ")",
};

class ForgotPassword extends Form {
    state = {
        data: { email: "" },
        backgroundImage: "",
        errors: {},
    };

    componentDidMount() {
        if (!isEmpty(this.props.authBg)) {
            this.setState({ backgroundImage: this.props.authBg });
        }
        this.props.resetResults();
        this.props.clearAlerts();
    }

    schema = {
        email: Joi.string()
            .email({ tlds: { allow: false } })
            .label("Email"),
    };

    componentWillReceiveProps = (nextProps) => {
        if (nextProps.errors) {
            this.setState({ errors: nextProps.errors });
        }
    };

    doSubmit = () => {
        const { data } = this.state;
        this.props.forgotUser(data, this.props.history);
    };

    render() {
        const { errors, data } = this.state;
        const { t, loading, successMsg } = this.props;
        return (
            <section
                className="d-flex align-items-center flex-column justify-content-center h-100"
                style={{
                    backgroundImage: "url(" + this.props.authBg + ")",
                    backgroundPosition: "center",
                    opacity: 0.9,
                }}
            >
                <div className="regis-form login-form radius-5">
                    <div className="d-flex mb-20">
                        <h5>{t.forgetPassword}</h5>
                    </div>
                    <form noValidate onSubmit={this.handleSubmit}>
                        <div className="form-group">
                            <input
                                type="email"
                                className={classnames("form-control", {
                                    "is-invalid": errors?.email,
                                })}
                                name="email"
                                placeholder="Email Address"
                                onChange={this.handleChange}
                                value={data?.email}
                            />
                            {errors?.email ? <div className="invalid-feedback">{errors?.email}</div> : null}
                        </div>

                        <div className="btn-wrap text-center">
                            <button type="auth" disabled={!this.validate() || loading} onClick={this.onSubmit} className="btn btn-primary">
                                {loading && (
                                    <Loader
                                        type="ThreeDots"
                                        color="#00BFFF"
                                        height={40}
                                        width={40}
                                        //3 secs
                                    />
                                )}
                                {loading && "Submitting"}
                                {!loading && t.submit}
                            </button>
                        </div>
                    </form>
                    {successMsg && <div class="mt-2 alert alert-success">{successMsg}</div>}
                </div>
            </section>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        errors: state.errors.errors,
        authBg: state.admin.admin.authBg,
        loading: state.common.loading,
        successMsg: state.common.success,
    };
};

export default connect(mapStateToProps, { forgotUser, resetResults, clearAlerts })(withRouter(ForgotPassword));
