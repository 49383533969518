import React from "react";
import ServiceItem from "./ServiceItem";
import isEmpty from "../../../utils/is-empty";

const Services = ({ itemData, services }) => {
    const { title1, title2, title3, title4, title5, title6, title7, title8, title9, desc1, desc2, desc3, desc4, desc5, desc6, desc7, desc8, desc9, price1, price2, price3, price4, price5, price6, price7, price8, price9 } = services;

    return (
        <div className="fade show active" id="home" role="tabpanel" aria-labelledby="home-tab" style={{ backgroundColor: "black" }}>
            <div className="form-row">
                {!isEmpty(title1) ? <ServiceItem itemData={itemData} title={title1} desc={desc1 || ""} price={price1 || ""} /> : null}
                {!isEmpty(title2) ? <ServiceItem itemData={itemData} title={title2} desc={desc2 || ""} price={price2 || ""} /> : null}
                {!isEmpty(title3) ? <ServiceItem itemData={itemData} title={title3} desc={desc3 || ""} price={price3 || ""} /> : null}
                {!isEmpty(title4) ? <ServiceItem itemData={itemData} title={title4} desc={desc4 || ""} price={price4 || ""} /> : null}
                {!isEmpty(title5) ? <ServiceItem itemData={itemData} title={title5} desc={desc5 || ""} price={price5 || ""} /> : null}
                {!isEmpty(title6) ? <ServiceItem itemData={itemData} title={title6} desc={desc6 || ""} price={price6 || ""} /> : null}
                {!isEmpty(title7) ? <ServiceItem itemData={itemData} title={title7} desc={desc7 || ""} price={price7 || ""} /> : null}
                {!isEmpty(title8) ? <ServiceItem itemData={itemData} title={title8} desc={desc8 || ""} price={price8 || ""} /> : null}
                {!isEmpty(title9) ? <ServiceItem itemData={itemData} title={title9} desc={desc9 || ""} price={price9 || ""} /> : null}
            </div>
        </div>
    );
};

export default Services;
