export const ADD_ADMIN_PACKAGES = "ADD_ADMIN_PACKAGES";
export const ADD_CATEGORY = "ADD_CATEGORY";
export const ADD_PACKAGES = "ADD_PACKAGES";
export const ADMIN_DATA_LOADED = "ADMIN_DATA_LOADED";
export const BLOCK_CONTINENT = "BLOCK_CONTINENT";
export const BLOCK_UNBLOCK_CONTINENT = "BLOCK_UNBLOCK_CONTINENT";
export const BLOCK_UNBLOCK_USER = "BLOCK_UNBLOCK_USER";
export const CLEAR_ALERTS = "CLEAR_ALERTS";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const CLEAR_LOCATION_OPTIONS = "CLEAR_LOCATION_OPTIONS";
export const CLEAR_NO_RESULTS = "CLEAR_NO_RESULTS";
export const CLEAR_OPTIONS_LOADING = "CLEAR_OPTIONS_LOADING";
export const CLEAR_SUCCESS_MESSAGES = "CLEAR_SUCCESS_MESSAGES";
export const DELETE_USER = "DELETE_USER";
export const DUMMY_ACTION = "DUMMY_ACTION";
export const ERROR_ALERT = "ERROR_ALERT";
export const GET_ABOUT_US = "GET_ABOUT_US";
export const GET_ADMIN_DATA = "GET_ADMIN_DATA";
export const GET_ADMIN_PACKAGES = "GET_ADMIN_PACKAGES";
export const GET_AFRICAN_COUNTRIES = "GET_AFRICAN_COUNTRIES";
export const GET_ALL_AFRICAN_COUNTRIES_OPTIONS = "GET_ALL_AFRICAN_COUNTRIES_OPTIONS";
export const GET_ALL_CONTINENTS = "GET_ALL_CONTINENTS";
export const GET_ALL_COUNTRIES = "GET_ALL_COUNTRIES";
export const GET_ALL_COUNTRIES_OPTIONS = "GET_ALL_COUNTRIES_OPTIONS";
export const GET_BLOCK_PLANNER_COUNT = "GET_BLOCK_PLANNER_COUNT";
export const GET_BLOCK_VENDOR_COUNT = "GET_BLOCK_VENDOR_COUNT";
export const GET_BLOCKED_COUNTRIES = "GET_BLOCKED_COUNTRIES";
export const GET_BRANCH1_CITY = "GET_BRANCH1_CITY";
export const GET_BRANCH1_COUNTRIES = "GET_BRANCH1_COUNTRIES";
export const GET_BRANCH1_STATE = "GET_BRANCH1_STATE";
export const GET_BRANCH2_CITY = "GET_BRANCH2_CITY";
export const GET_BRANCH2_COUNTRIES = "GET_BRANCH2_COUNTRIES";
export const GET_BRANCH2_STATE = "GET_BRANCH2_STATE";
export const GET_BRANCH3_CITY = "GET_BRANCH3_CITY";
export const GET_BRANCH3_COUNTRIES = "GET_BRANCH3_COUNTRIES";
export const GET_BRANCH3_STATE = "GET_BRANCH3_STATE";
export const GET_BUSINESS_TITLE_OPTIONS = "GET_BUSINESS_TITLE_OPTIONS";
export const GET_CATEGORIES_OPTIONS = "GET_CATEGORIES_OPTIONS";
export const GET_CATEGORIES_OPTIONS_FOR_FILTERS = "GET_CATEGORIES_OPTIONS_FOR_FILTERS";
export const GET_CATEGORY = "GET_CATEGORY";
export const GET_CATERING = "GET_CATERING";
export const GET_CATERING_OPTIONS = "GET_CATERING_OPTIONS";
export const GET_CITIES_BY_STATE = "GET_CITIES_BY_STATE";
export const GET_CITY_OPTIONS = "GET_CITY_OPTIONS";
export const GET_CONTINENTS = "GET_CONTINENTS";
export const GET_COUNTRIES_BY_CONTINENT = "GET_COUNTRIES_BY_CONTINENT";
export const GET_COUNTRIES_BY_CONTINENT_OPTIONS = "GET_COUNTRIES_BY_CONTINENT_OPTIONS";
export const GET_CURRENT_PLANNER = "GET_CURRENT_PLANNER";
export const GET_CURRENT_PROFILE = "GET_CURRENT_PROFILE";
export const GET_DASHBOARD_LANG = "GET_DASHBOARD_LANG";
export const GET_ERRORS = "GET_ERRORS";
export const GET_EVENT_TYPE_OPTIONS = "GET_EVENT_TYPE_OPTIONS";
export const GET_EVENT_TYPE_OPTIONS_FOR_FILTERS = "GET_EVENT_TYPE_OPTIONS_FOR_FILTERS";
export const GET_EVENT_TYPES = "GET_EVENT_TYPES";
export const GET_GENDER_OPTIONS = "GET_GENDER_OPTIONS";
export const GET_IMAGES_FROM_ADMIN = " GET_IMAGES_FROM_ADMIN";
export const GET_KEYWORDS = "GET_KEYWORDS";
export const GET_LANGUAGE = "GET_LANGUAGE";
export const GET_LOGIN_ERRORS = "GET_LOGIN_ERRORS";
export const GET_NOTICEBOARD = "GET_NOTICEBOARD";
export const GET_OFFICE = "GET_OFFICE";
export const GET_PACKAGES = "GET_PACKAGES";
export const GET_PLANNER_COUNT = "GET_PLANNER_COUNT";
export const GET_PLANNER_ITEM = "GET_PLANNER_ITEM";
export const GET_PLANNER_ITEM_PACKAGES = "GET_PLANNER_ITEM_PACKAGES";
export const GET_PLANNERS = "GET_PLANNERS";
export const GET_PROFILE = "GET_PROFILE";
export const GET_PROFILES = "GET_PROFILES";
export const GET_REVIEWS = "GET_REVIEWS";
export const GET_REGISTER_ERRORS = "GET_REGISTER_ERRORS";
export const GET_RESET_PASSWORD_ERRORS = "GET_RESET_PASSWORD_ERRORS";
export const GET_RESULTS = "GET_RESULTS";
export const GET_REVENUE_OPTIONS = "GET_REVENUE_OPTIONS";
export const GET_STATES = "GET_STATES";
export const GET_STATES_OPTIONS = "GET_STATES_OPTIONS";
export const GET_TARGET_MARKET_OPTIONS = "GET_TARGET_MARKET_OPTIONS";
export const GET_TERMS = "GET_TERMS";
export const GET_TRANSLATED_LABELS = "GET_TRANSLATED_LABELS";
export const GET_UNBLOCK_COUNTRIES = "GET_UNBLOCK_COUNTRIES";
export const GET_UNBLOCKED_AFRICAN_COUNTRIES_OPTIONS = "GET_UNBLOCKED_AFRICAN_COUNTRIES_OPTIONS";
export const GET_UNBLOCKED_CONTINENT_OPTIONS = "GET_UNBLOCKED_CONTINENT_OPTIONS";
export const GET_UNBLOCKED_CONTINENTS = "GET_UNBLOCKED_CONTINENTS";
export const GET_USER_STATS = "GET_USER_STATS";
export const GET_USERS = "GET_USERS";
export const GET_VENDOR = "GET_VENDOR";
export const GET_VENDOR_COUNT = "GET_VENDOR_COUNT";
export const GET_VENDOR_ITEM = "GET_VENDOR_ITEM";
export const GET_VENDOR_ITEM_SERVICES = "GET_VENDOR_ITEM_SERVICES";
export const GET_VENDOR_RESULTS = "GET_VENDOR_RESULTS";
export const GET_VENDOR_SERVICES = "GET_VENDOR_SERVICES";
export const GET_VENDORS = "GET_VENDORS";
export const LOADING = "LOADING";
export const NO_RESULTS_FOUND = "NO_RESULTS_FOUND";
export const NOTICEBOARD_LOADED = "NOTICEBOARD_LOADED";
export const ON_CLICK_CANCEL = "ON_CLICK_CANCEL";
export const ON_CLICK_EDIT = "ON_CLICK_EDIT";
export const ON_CLICK_SAVE = "ON_CLICK_SAVE";
export const ON_FAIL_VENDOR_PROFILE = "ON_FAIL_VENDOR_PROFILE";
export const ON_START = "ON_START";
export const ON_SUCCESS = "ON_SUCCESS";
export const ON_SUCCESS_VENDOR_PROFILE = "ON_SUCCESS_VENDOR_PROFILE";
export const PACKAGES_LOADED = "PACKAGES_LOADED";
export const PROFILE_LOADED = "PROFILE_LOADED";
export const PROFILE_UNLOADED = "PROFILE_UNLOADED";
export const PROFILES_LOADING = "PROFILES_LOADING";
export const REVIEWS_LOADED = "REVIEWS_LOADED";
export const RESET_PACK_AND_SERV = "RESET_PACK_AND_SERV";
export const RESET_RESULTS = "RESET_RESULTS";
export const SEARCH_BY_ADMIN = "SEARCH_BY_ADMIN";
export const SEARCH_RESULTS = "SEARCH_RESULTS";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const SET_FILTER_OPTIONS = "SET_FILTER_OPTIONS";
export const SET_FILTERED_PLANNERS = "SET_FILTERED_PLANNERS";
export const SET_FILTERED_PLANNERS_TO_NULL = "SET_FILTERED_PLANNERS_TO_NULL";
export const SET_FILTERED_VENDORS = "SET_FILTERED_VENDORS";
export const SET_FILTERED_VENDORS_TO_NULL = " SET_FILTERED_VENDORS_TO_NULL";
export const SET_LOADING_FALSE = "SET_LOADING_FALSE";
export const SET_OPTIONS_LOADING = "SET_OPTIONS_LOADING";
export const SET_PLANNER_OPTIONS_LOADED = "SET_PLANNER_OPTIONS_LOADED";
export const SET_VENDOR_OPTIONS_LOADED = "SET_VENDOR_OPTIONS_LOADED";
export const SUCCESS_ALERT = "SUCCESS_ALERT";
export const SUCCESSFULLY_RESET_PASSWORD = "SUCCESSFULLY_RESET_PASSWORD";
export const UNBLOCK_CONTINENT = "UNBLOCK_CONTINENT";
export const UNBLOCK_COUNTRY = "UNBLOCK_COUNTRY";
export const UPLOAD_IMAGES = "UPLOAD_IMAGES";
