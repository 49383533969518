import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Joi from "joi";
import Form from "../../common/form";
import { registerVendor } from "../../../actions/vendorActions";
import { getUnBlockedContinentsOptions, getCountriesByContinentOptions, getStatesOptions, getCityOptions, getRevenueOptions } from "../../../actions/optionActions";

import AboutVendorView from "./AboutVendorView";

class AboutVendor extends Form {
    state = {
        data: {
            currentRevenue: "$0",
            operatingSince: "",
            firstName: "",
            lastName: "",
            phoneNo1: "",
            streetAdress1: "",
            streetAdress2: "",
            apiSuite: "",
            zipCode: "",
            continent: "",
            country: "",
            st: "",
            city: "",
            website: "",
        },
        errors: {},
    };

    schema = {
        firstName: Joi.string().min(2).required().label("First Name"),
        lastName: Joi.string().min(2).required().label("Last Name"),
        // phoneNo1: Joi.string().min(6).required().label("PhoneNo1"),
        streetAdress1: Joi.string().min(4).required().label("Street Adress 1"),
        continent: Joi.string().required().label("Continent"),
        country: Joi.string().required().label("Country"),
        st: Joi.string().required().label("State"),
        city: Joi.string().required().label("City"),
    };

    async componentDidMount() {
        await this.props.getUnBlockedContinentsOptions();
        await this.props.getRevenueOptions();
    }

    onChange = (e) => {
        const data = { ...this.state.data, [e.target.name]: e.target.value };
        this.setState({ data });
    };

    getCountriesbyContint = (e) => {
        e.preventDefault();

        this.setState({ data: { ...this.state.data, [e.target.name]: e.target.value } });
        this.props.getCountriesByContinentOptions({ continent: e.target.value });
    };

    getStatesbyCountry = (e) => {
        e.preventDefault();
        let code = e.target.value;

        this.setState({ data: { ...this.state.data, country: code } });
        this.props.getStatesOptions({ code });
    };

    getCities = (e) => {
        const index = e.nativeEvent.target.selectedIndex;
        const label = e.nativeEvent.target[index].text;

        this.setState({ data: { ...this.state.data, st: label } });
        this.props.getCityOptions({ code: e.target.value });
    };

    doSubmit = () => {
        const aboutVendor = {
            lang: this.props.lang,
            firstName: this.state.data.firstName,
            lastName: this.state.data.lastName,
            phoneNo1: this.state.data.phoneNo1,
            streetAdress1: this.state.data.streetAdress1,
            streetAdress2: this.state.data.streetAdress2,
            apiSuite: this.state.data.apiSuite,
            city: this.state.data.city,
            continent: this.state.data.continent,
            st: this.state.data.st,
            zipCode: this.state.data.zipCode,
            country: this.state.data.country,
            website: this.state.data.website,
            operatingSince: this.state.data.operatingSince,
            currentRevenue: this.state.data.currentRevenue,
        };

        this.props.registerVendor(aboutVendor, this.props.history);
    };

    render() {
        const { data, errors } = this.state;
        const { unBlockedContinentOptions, countriesByContinentOptions, stateOptions, cityOptions, revenueOptions, t } = this.props;

        return <AboutVendorView t={t} websiteBg={this.props.websiteBg} data={data} continentOptions={unBlockedContinentOptions} countryOptions={countriesByContinentOptions} stateOptions={stateOptions} cityOptions={cityOptions} revenueOptions={revenueOptions} getCountriesbyContint={this.getCountriesbyContint} getStatesbyCountry={this.getStatesbyCountry} getCities={this.getCities} onChange={this.onChange} onSubmit={this.handleSubmit} isEnabled={this.validate()} history={this.props.history} errors={Object.values(errors)} />;
    }
}
const mapStateToProps = (state) => ({
    errors: state.errors.errors,
    auth: state.auth,
    unBlockedContinentOptions: state.options.unBlockedContinentOptions,
    countriesByContinentOptions: state.options.countriesByContinentOptions,
    stateOptions: state.options.stateOptions,
    cityOptions: state.options.cityOptions,
    revenueOptions: state.options.revenueOptions,
    lang: state.common.language,
});

export default connect(mapStateToProps, {
    getUnBlockedContinentsOptions,
    getCountriesByContinentOptions,
    getStatesOptions,
    getCityOptions,
    getRevenueOptions,
    registerVendor,
})(withRouter(AboutVendor));
