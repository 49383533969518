import React from "react";
import "../../../styles/UploadImage.css";

import { toast } from "react-toastify";

const ImageUpload = ({ onClick, onChangeImage, label, disabled, type, id, data }) => {
    return (
        <div className="d-flex align-items-center my-3">
            <div className="flex-fill">
                <div className="custom-file">
                    <input type="file" onChange={onChangeImage} disabled={disabled} className="custom-file-input" id={id + "i"} required multiple />

                    <label id={id + "ilabel"} className="custom-file-label text-left" htmlFor={id + "i"}>
                        Choose file...
                    </label>
                    <div className="invalid-feedback">Example invalid custom file feedback</div>
                </div>
            </div>

            <div className="ml-2">
                <button type={type} disabled={disabled} id={id} onClick={onClick} className="btn btn-primary">
                    {label}
                </button>
            </div>

            {/* <div >{imagePreview}</div> */}

            {/*<input*/}
            {/*style={{ border: "5px solid black" }}*/}
            {/*type="file"*/}
            {/*id="idofinput"*/}
            {/*className="hidden"*/}
            {/*accept="image/png, image/jpeg"*/}
            {/*onChange={onChange}*/}
            {/*disabled={disabled}*/}
            {/*/>*/}
            {/*<button*/}
            {/*type={type}*/}
            {/*disabled={disabled}*/}
            {/*id={id}*/}
            {/*className="uploadButton"*/}
            {/*onClick={onClick}*/}
            {/*>*/}
            {/*{label}*/}
            {/*</button>*/}
            {data ? <div className="ml-2 image-show" style={{ backgroundImage: "url(" + data + ")" }}></div> : null}
            {data ? <img className="ml-2" src="https://s2.svgbox.net/materialui.svg?ic=check&amp;color=66BB66" alt="Tick Icon" width={40} /> : null}
        </div>
    );
};

export default ImageUpload;
