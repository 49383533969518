export const getCompareData = () => async (dispatch) => {
    dispatch({ type: "GET_COMPARE_DATA" });
};

export const setCompareData =
    (action = "CLEAR_ALL", item = {}) =>
    async (dispatch) => {
        const payload = { action, item };
        dispatch({ type: "SET_COMPARE_DATA", payload });
    };
