import React from "react";
import "../../../styles/ui.css";

const GreenButton = ({ label, type, onClick, disabled }) => {
    return (
        <button className="btn btn-success w-75" type={type} onClick={onClick} disabled={disabled}>
            {label}
        </button>
    );
};

export default GreenButton;
