import React, { Component } from "react";
import ImageGallery from "react-image-gallery";
import Modal from "react-modal";
import isEmpty from "../../../utils/is-empty";
import Facebook from "./icons/facebook.svg";
import Instagram from "./icons/instagram.svg";
import LinkedIn from "./icons/linkedin.svg";
import Twitter from "./icons/twitter.svg";
import WWW from "./icons/www.svg";
import Youtube from "./icons/youtube.svg";
import PlannerPlaceholder from "../../../img/images/default-planner.jpg";
import VendorPlaceHolder from "../../../img/images/default-vendor.jpg";

class SingleItem extends Component {
    state = {
        isOpen: false,
        items: [],
    };

    setModalOpen = () => {
        this.setState({ isOpen: true });
    };

    closeModal = () => {
        this.setState({ isOpen: false });
    };
    render() {
        if (!this.props.plannerItem) {
            return <div>Loading</div>;
        }

        const { t } = this.props;
        const { cateringCapacity, categories, eventTypes, description, targetMarket, officeMobileNo, officeTele1, officeTele2, phnIsVisible, instaAdress, youtubeAdress, twitterAdress, fbAdress, website, linkdnAdress, images, storeName } = this.props.plannerItem;

        const customStyles = {
            content: {
                top: "50%",
                left: "50%",
                right: "auto",
                bottom: "auto",
                marginRight: "-50%",
                transform: "translate(-50%, -50%)",
            },
        };

        //React Hooks
        // const [modalIsOpen, setPhotoIndex] = useState(0);

        const imageValues = !isEmpty(images) ? Object.values(images).filter(Boolean) : [];
        const renderModalImages = (imageValues) => {
            let items = [];
            for (let i = 0; i < imageValues.length; i++) {
                if (imageValues[i]) {
                    items.push({
                        original: imageValues[i],
                        originalClass: "react-img-gallery",
                    });
                }
            }
            this.setState({ items, isOpen: true });
        };

        const targetMarketStyling = (tmarket) => {
            if (tmarket === "up") return t.upMarket;
            if (tmarket === "mix") return t.marketMix;
            if (tmarket === "stream") return t.mainStream;
        };

        return (
            <div className="package-thumb-wrap ">
                <div className="package-thumb-header row mb-2">
                    <div className="col-md-3">
                        <h6>
                            <strong>{storeName}</strong>
                        </h6>
                    </div>
                    <div className="col-md-6">
                        <h6>
                            {t.canCater}
                            <span className="nub-count"> [{Number(cateringCapacity || 0)}] </span>
                            {t.guest}
                        </h6>
                    </div>
                    <div className="col-md-3">
                        <div className="post-meta mb-0 ">
                            <h5>{t.categories + ":"} </h5>
                            <ul>{categories ? categories.map((category, key) => <li key={key}>{category.label}</li>) : null}</ul>
                        </div>
                    </div>
                </div>
                <div className="row mb-4">
                    <div className="col-md-7">
                        <div className="package-thumb-caption">
                            <h5>{t.description}</h5>
                            <p style={{ overflowWrap: "anywhere" }}>{description ? description : null}</p>
                        </div>
                        {
                            /* this.props.userType === "planner" && */ eventTypes ? (
                                <div className="post-meta">
                                    <h5>{t.eventType}</h5>
                                    <ul>
                                        {eventTypes.map((eventType, key) => (
                                            <li>
                                                <a key={key}>{eventType.label}</a>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            ) : null
                        }
                    </div>
                    <div className="col-md-5">
                        {imageValues.length ? (
                            <div className="ratio16x9" onClick={() => renderModalImages(imageValues)}>
                                <img src={imageValues[0] || ""} alt="First" />
                            </div>
                        ) : (
                            <div className="ratio16x9">
                                <img src={this.props.userType === "planner" ? PlannerPlaceholder : VendorPlaceHolder} alt="planner-directory-placeholder" />
                            </div>
                        )}
                    </div>
                </div>
                <div className="package-thumb-footer">
                    <div className="row my-2">
                        <div className="col-md mx-2">
                            <div className="row">
                                {phnIsVisible &&
                                    [officeMobileNo, officeTele1, officeTele2].filter(Boolean).map((phn, idx) => (
                                        <div key={idx} className="d-flex align-items-center mx-2">
                                            <i class="fa fa-phone" />
                                            &nbsp; {phn}
                                        </div>
                                    ))}
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="text-right">
                                <ul className="contact-list social-list">
                                    {website ? (
                                        <li>
                                            <a href={website} target="_blank" rel="noopener noreferrer">
                                                <img src={WWW} alt="Website Adress" />
                                            </a>
                                        </li>
                                    ) : null}
                                    {fbAdress ? (
                                        <li>
                                            <a href={fbAdress} target="_blank" rel="noopener noreferrer">
                                                <img className="" src={Facebook} alt="Facebook Addres" />
                                            </a>
                                        </li>
                                    ) : null}
                                    {twitterAdress ? (
                                        <li>
                                            <a href={twitterAdress} target="_blank" rel="noopener noreferrer">
                                                <img src={Twitter} alt="LinkedIn Adress" />
                                            </a>
                                        </li>
                                    ) : null}
                                    {linkdnAdress ? (
                                        <li>
                                            <a href={linkdnAdress} target="_blank" rel="noopener noreferrer">
                                                <img src={LinkedIn} alt="LinkedIn Adress" />
                                            </a>
                                        </li>
                                    ) : null}

                                    {instaAdress ? (
                                        <li>
                                            <a href={instaAdress} target="_blank" rel="noopener noreferrer">
                                                <img src={Instagram} alt="Instagram Adress" />
                                            </a>
                                        </li>
                                    ) : null}
                                    {youtubeAdress ? (
                                        <li>
                                            <a href={youtubeAdress} target="_blank" rel="noopener noreferrer">
                                                <img src={Youtube} alt="Instagram Adress" />
                                            </a>
                                        </li>
                                    ) : null}
                                </ul>
                                <span className={targetMarket}>{targetMarketStyling(targetMarket)}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal isOpen={this.state.isOpen} onRequestClose={() => this.closeModal()} style={customStyles} overlayClassName="react-modal-overlay">
                    <ImageGallery items={this.state.items} showBullets={true} showIndex={true} showThumbnails={false} showPlayButton={false} showFullscreenButton={false} showNav={false} autoPlay={true} infinite={false} slideDuration={100} onImageError={this.props.userType === "/planners" ? PlannerPlaceholder : VendorPlaceHolder} />
                </Modal>
            </div>
        );
    }
}

export default SingleItem;
